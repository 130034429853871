import React, { useState, useEffect } from "react"
import { Form, Select, Input, DatePicker, InputNumber } from "antd"
import { UsersClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"
import moment from "moment"
import { useIntl } from "react-intl"
const EditModal = ({
  isCreate,
  isNew,
  visible,
  onCancel,
  options,
  record,
  refresh,
  editRole,
}) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const { formatMessage } = useIntl()

  const userTitle = formatMessage({ id: "user" })
  const staffNoLabel = formatMessage({ id: "staffNo" })
  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNoLabel }
  )

  const staffNameLabel = formatMessage({ id: "staffName" })
  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNameLabel }
  )

  const companyLabel = formatMessage({ id: "company" })
  const companyPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: companyLabel }
  )

  const siteLabel = formatMessage({ id: "site" })
  const sitePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: siteLabel }
  )

  const positionLabel = formatMessage({ id: "position" })
  const positionPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: positionLabel }
  )

  const roleLabel = formatMessage({ id: "role" })
  const rolePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: roleLabel }
  )

  const passwordLabel = formatMessage({ id: "password" })
  const passwordPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: passwordLabel }
  )

  const firstNameLabel = formatMessage({ id: "firstName" })
  const firstNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: firstNameLabel }
  )

  const lastNameLabel = formatMessage({ id: "lastName" })
  const lastNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    {
      name: lastNameLabel,
    }
  )

  const dateJoinedLabel = formatMessage({ id: "dateJoined" })
  const yearInCurrentPositionLabel = formatMessage({
    id: "yearInCurrentPosition",
  })
  const emailLabel = formatMessage({ id: "email" })

  const emailPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: emailLabel }
  )

  const passwordFormate = formatMessage({ id: "passwordFormate" })

  const onlyUpperCaseLetter = formatMessage({ id: "onlyUpperCaseLetter" })

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
        dateJoined: record.dateJoined ? moment(record.dateJoined) : null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)

    const submitValues = {
      ...values,
      roleName: values.roleName ? values.roleName : null,
      dateJoined: moment(values.dateJoined).utcOffset(0, true).toJSON(),
    }
    const client = new UsersClient()
    try {
      if (isCreate) {
        await client.create(submitValues)
        showSuccess("Add Request Success")
      } else {
        await client.edit(record.staffNo, submitValues)
        showSuccess("Edit Request Success")
      }
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add User" : "Edit User", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isTranslate
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={userTitle}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="staffNo"
          label={staffNoLabel}
          hidden={!isCreate}
          rules={[{ required: isCreate }]}
        >
          <Input placeholder={staffNoPlaceholder} />
        </Form.Item>

        <Form.Item
          name="name"
          label={staffNameLabel}
          rules={[{ required: true }]}
        >
          <Input placeholder={staffNamePlaceholder} />
        </Form.Item>

        {isCreate && (
          <Form.Item
            name="password"
            label={passwordLabel}
            rules={[
              { required: true },
              {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: passwordFormate,
              },
            ]}
          >
            <Input.Password placeholder={passwordPlaceholder} />
          </Form.Item>
        )}
        <Form.Item
          name="firstName"
          label={firstNameLabel}
          rules={[
            { required: true },
            { pattern: /[A-Z]/, message: onlyUpperCaseLetter },
          ]}
        >
          <Input placeholder={firstNamePlaceholder} />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={lastNameLabel}
          rules={[
            { required: true },
            { pattern: /[A-Z]/, message: onlyUpperCaseLetter },
          ]}
        >
          <Input placeholder={lastNamePlaceholder} />
        </Form.Item>

        <Form.Item
          name="companyId"
          label={companyLabel}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder={companyPlaceholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.companyList.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="siteOrDepartmentId"
          label={siteLabel}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder={sitePlaceholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.siteOrDepartmentList.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="positionId"
          label={positionLabel}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder={positionPlaceholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.positionList.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="roleName"
          label={roleLabel}
          style={!editRole && { display: "none" }}
          // use style to hand in roleName as well
        >
          <Select
            disabled={!editRole}
            allowClear
            placeholder={rolePlaceholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.roleList.map(item => (
              <Option value={item.name}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dateJoined" label={dateJoinedLabel}>
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="yearsInCurrentPosition"
          label={yearInCurrentPositionLabel}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>
        <Form.Item
          name="email"
          messageVariables={{ label: "" }}
          label={emailLabel}
          rules={[{ type: "email" }]}
        >
          <Input placeholder={emailPlaceholder} />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
