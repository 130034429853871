import React, { useState, useEffect, useCallback } from "react"
import { Space, Spin, Button, Upload, message } from "antd"
import "../admin_table.less"
import { YesOrNoIcon, EditButton, PanelTable } from "lib"
import {
  UsersClient,
  ApprovalRoleClient,
  StaffDefaultApproversClient,
} from "@api"
import { showError, errorMessage, DownloadData, groupBy } from "@action"
import EditModal from "./EditModal"
import SearchForm from "./SearchForm"
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

const DefaultApprover = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState({
    currentPage: 0,
    totalCount: 0,
    pageSize: 0,
  })
  const [roleOptions, setRoleOptions] = useState([])
  const [isInit, setIsInit] = useState(true)
  const [modalVars, setModalVars] = useState({
    visible: false,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    orderBy: "staffNo",
    isAscend: true,
    staffNo: null,
    staffName: null,
    companyIdList: null,
    siteIdList: null,
    positionIdList: null,
    roleNameList: null,
    pageSize: 10,
    pageIndex: 0,
  })

  const handleUpload = async file => {
    setIsLoading(true)
    try {
      let client = new StaffDefaultApproversClient()
      await client.upload({ data: file, fileName: file.name })

      message.success("Upload " + file.name + " success !")
      getData(searchParams)
    } catch (err) {
      console.log(err.response)
      errorMessage("Upload " + file.name, err)
    } finally {
      setIsLoading(false)
    }
  }

  const uploadProps = {
    name: "file",
    accept: ".csv, .xlsx",
    showUploadList: false,
    customRequest: ({ file }) => {
      handleUpload(file)
    },
  }

  const [columns, setColumns] = useState([
    {
      title: <FormattedMessage id="staffNo" />,
      dataIndex: "staffNo",
      sorter: true,
    },
    {
      title: <FormattedMessage id="staffName" />,
      dataIndex: "name",
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "positionName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="action" />,
      render: (_, record) => (
        <EditButton
          isTranslate
          onClick={() => {
            setModalVars({
              record: record,
              visible: true,
            })
          }}
        />
      ),
    },
  ])

  const getData = useCallback(() => {
    const callAPI = async params => {
      setIsLoading(true)
      const client = new UsersClient()
      const res = await client
        .search(
          params.orderBy,
          params.isAscend,
          params.staffNo,
          params.staffName,
          null,
          null,
          params.positionIdList,
          null,
          params.pageSize,
          params.pageIndex + 1
        )
        .catch(err => {
          console.log(err)
          return { items: [] }
        })

      const newDataSource = res.items.map(item => {
        const newItem = item
        newItem.staffDefaultApprovers.forEach(approver => {
          newItem[approver.name] = true
        })
        return newItem
      })

      setDataSource(newDataSource)
      setPageMeta({
        currentPage: res.pageIndex,
        totalCount: res.totalCount,
        pageSize: params.pageSize,
      })

      setIsLoading(false)
    }
    callAPI(searchParams)
  }, [searchParams])

  useEffect(() => {
    const getRoleOptions = async () => {
      try {
        const client = new ApprovalRoleClient()
        const res = await client.get(0, 0, "name", true)

        const approvalRoleGroup = res.content.approvalRoleGroup
        setRoleOptions(
          approvalRoleGroup.map(option => ({
            label: option.name,
            value: option.id,
          }))
        )
        const backgrounds = ["#958655", "#dfd8c5", "#f1eee6"]
        const newApprovalRoleGroup = groupBy(approvalRoleGroup, "type")

        const roleColumn = Object.keys(newApprovalRoleGroup)
          .sort((pre, cur) =>
            pre[0].toUpperCase() <= cur[0].toUpperCase() ? -1 : 1
          )
          .map((key, index) => ({
            title: <div style={{ background: backgrounds[index] }}>{key}</div>,
            className: "approver_path",
            children: approvalRoleGroup
              .filter(item => item.type === key)
              .map(item => ({
                title: item.name,
                dataIndex: item.name,
                align: "center",
                render: text => <YesOrNoIcon value={text} />,
              })),
          }))

        const columns = [
          {
            title: <FormattedMessage id="staffNo" />,
            dataIndex: "staffNo",
            sorter: true,
          },
          {
            title: <FormattedMessage id="staffName" />,
            dataIndex: "name",
            sorter: true,
          },
          {
            title: <FormattedMessage id="position" />,
            dataIndex: "positionName",
            sorter: true,
          },
        ]

        const newColumns = columns.concat(roleColumn)

        newColumns.push({
          title: <FormattedMessage id="action" />,
          render: (_, record) => (
            <EditButton
              isTranslate
              onClick={() => {
                setModalVars({
                  visible: true,
                  record: record,
                })
              }}
            />
          ),
        })
        setColumns(newColumns)
        setIsInit(false)
      } catch (err) {
        console.log(err)
        let errmsg = JSON.parse(err.response)
        showError(errmsg.errors)
      }
    }

    getRoleOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return !isInit ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
      />

      <EditModal
        visible={modalVars.visible}
        record={modalVars.record}
        options={roleOptions}
        onCancel={() => {
          setModalVars({
            visible: false,
            record: null,
          })
        }}
        refresh={() => getData()}
      />

      <Space size="small" className="table-Buttom">
        <Upload {...uploadProps}>
          <Button shape="round" icon={<UploadOutlined />}>
            <FormattedMessage id="upload" values={{ name: "" }} />
          </Button>
        </Upload>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => {
            DownloadData(StaffDefaultApproversClient)
          }}
        >
          <FormattedMessage id="download" />
        </Button>
      </Space>
      <PanelTable
        isTranslate
        bordered
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={value =>
          setSearchParams({ ...searchParams, ...value })
        }
        searchParams={searchParams}
        scroll={{ x: true }}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default DefaultApprover
