import React, { useEffect, useState } from "react"
import { Button, Space, Spin } from "antd"
import {
  UndoOutlined,
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import "../admin_table.less"
import { UsersClient, OtherClient, RolesClient } from "@api"
import { getPermission } from "@services/auth"
import { showError, showSuccess, DownloadData, transformDate } from "@action"
import { PanelTable, EditButton, InactiveTag, EnableButton } from "lib"
import SearchForm from "./SearchForm"
import ResetModal from "./ResetModal"
import EditModal from "./EditModal"
import UploadModal from "./uploadModal"
import StatusModal from "./StatusModal"
import { FormattedMessage, useIntl } from "react-intl"

const Users = () => {
  const [statusModal, setStatusModal] = useState({
    visible: false,
    record: null,
  })
  const [editUser, setEditUser] = useState(false)
  const [staffNo, setStaffNo] = useState(null)
  const [resetVisible, setResetVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [options, setOptions] = useState(undefined)
  const [pageMeta, setPageMeta] = useState({
    currentPage: 0,
    totalCount: 0,
    pageSize: 0,
  })
  const [roleOptions, setRoleOptions] = useState([])
  const [isInit, setIsInit] = useState(true)
  const [editRole, setEditRole] = useState(false)
  const [isSiteAdmin, setisSiteAdmin] = useState(false)
  const [modalVars, setModalVars] = useState({
    isCreate: true,
    isNew: true,
    record: null,
    visible: false,
  })
  const [uploadModal, setUploadModal] = useState({
    visible: false,
  })
  const [searchParams, setSearchParams] = useState({
    orderBy: "staffNo",
    isAscend: true,
    staffNo: null,
    staffName: null,
    companyIdList: null,
    siteIdList: null,
    positionIdList: null,
    roleNameList: null,
    pageSize: 10,
    pageIndex: 0,
  })

  const getOptions = async () => {
    try {
      let client = new OtherClient()
      let result = await client.get()
      setOptions(result.content)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const getRoleOptions = async () => {
    try {
      let client = new RolesClient()
      let result = await client.get(0, 0, "name", true)
      setRoleOptions(result.content.roleGroup)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const getData = async params => {
    setIsLoading(true)

    const client = new UsersClient()
    const res = await client
      .search(
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName,
        params.companyIdList,
        params.siteIdList,
        params.positionIdList,
        params.roleNameList,
        params.pageSize,
        params.pageIndex + 1
      )
      .catch(err => {
        console.log(err)
        return {
          items: [],
        }
      })
    setDataSource(res.items)
    setPageMeta({
      currentPage: res.pageIndex,
      totalCount: res.totalCount,
      pageSize: params.pageSize,
    })
    setIsInit(false)
    setIsLoading(false)
  }

  const changeAble = async data => {
    let client = new UsersClient()
    try {
      await client.enableOrDisable({
        staffNo: data.staffNo,
        isEnabled: true,
        disableRemarks: null,
      })
      showSuccess("Enable Item Success")
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const checkPermission = item => {
    if (item === "User") {
      setEditUser(true)
      getOptions()
    }
    if (item === "SiteAdmin") {
      setisSiteAdmin(true)
      getOptions()
    }
    if (item === "Role") {
      setEditRole(true)
      getRoleOptions()
    }
  }

  const changePermission = arr => {
    if (arr === null) return null
    arr.forEach(item => {
      checkPermission(item)
    })
    return null
  }

  useEffect(() => {
    const inItPage = async () => {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      changePermission(response)

      getData(searchParams)
    }
    inItPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isInit) {
      getData(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  const disableLabel = useIntl().formatMessage({ id: "disable" })
  const columns = [
    {
      title: <FormattedMessage id="staffNo" />,
      dataIndex: "staffNo",
      sorter: true,
    },
    {
      title: <FormattedMessage id="staffName" />,
      dataIndex: "name",
      sorter: true,
    },
    {
      title: <FormattedMessage id="company" />,
      dataIndex: "companyName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "siteOrDepartmentName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "positionName",
      sorter: true,
    },

    {
      title: <FormattedMessage id="role" />,
      dataIndex: "roleName",
      responsive: editRole ? ["xxl", "xl", "lg", "md", "sm", "xs"] : [],
      render: text => text ?? " - ",
      sorter: true,
    },
    {
      title: <FormattedMessage id="dateJoined" />,
      dataIndex: "dateJoined",
      sorter: true,
      render: date => transformDate(date) ?? " - ",
    },
    {
      title: <FormattedMessage id="yearInCurrentPosition" />,
      dataIndex: "yearsInCurrentPosition",
      render: text => (text || text === 0 ? text : " N/A "),
      sorter: true,
    },
    {
      title: <FormattedMessage id="email" />,
      dataIndex: "email",
      render: text => text ?? " - ",
      sorter: true,
    },
    {
      title: <FormattedMessage id="status" />,
      dataIndex: "isEnabled",
      sorter: true,
      render: (isEnabled, { disableRemarks }) => (
        <InactiveTag value={isEnabled} remarks={disableRemarks} />
      ),
    },
    {
      title: <FormattedMessage id="action" />,
      render: (_, record) => {
        const isEnable = record.isEnabled && !record.disableRemarks
        return (
          <Space size="small">
            {editUser && (
              <EditButton
                isTranslate
                onClick={() => {
                  setModalVars({
                    isNew: false,
                    isCreate: false,
                    record: record,
                    visible: true,
                  })
                }}
              />
            )}
            <Button
              shape="round"
              icon={<UndoOutlined />}
              size="small"
              onClick={() => {
                setStaffNo(record.staffNo)
                setResetVisible(true)
              }}
            >
              <FormattedMessage id="changePW" />
            </Button>
            {isEnable ? (
              <Button
                size="small"
                shape="round"
                icon={<CloseOutlined />}
                onClick={() => {
                  setStatusModal({ visible: true, record: record })
                }}
              >
                {disableLabel}
              </Button>
            ) : (
              <EnableButton
                isTranslate
                isEnabled={false}
                onClick={() => changeAble(record)}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return options && !isInit ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
        options={{ ...options, roleOptions: roleOptions }}
        editUser={editUser}
        editRole={editRole}
      />

      <Space size="small" className="table-Buttom">
        {editUser && (
          <Button
            shape="round"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalVars({
                isNew: true,
                isCreate: true,
                record: null,
                visible: true,
              })
            }}
          >
            <FormattedMessage id="add" />
          </Button>
        )}

        {editUser && (
          <Button
            shape="round"
            icon={<UploadOutlined />}
            onClick={() => {
              setUploadModal({
                visible: true,
              })
            }}
          >
            <FormattedMessage id="upload" values={{ name: "" }} />
          </Button>
        )}

        {editUser && (
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => {
              DownloadData(UsersClient)
            }}
          >
            <FormattedMessage id="download" />
          </Button>
        )}
      </Space>
      <PanelTable
        isTranslate
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={value =>
          setSearchParams({ ...searchParams, ...value })
        }
        searchParams={searchParams}
        defaultOrder={{ orderBy: "staffNo", isAscend: true }}
        scroll={{ x: true }}
      />
      <ResetModal
        staffNo={staffNo}
        visible={resetVisible}
        onCancel={() => {
          setResetVisible(false)
        }}
      />
      <EditModal
        {...modalVars}
        onCancel={() => {
          setModalVars({
            isCreate: true,
            isNew: true,
            record: null,
            visible: false,
          })
        }}
        editRole={editRole}
        refresh={() => getData(searchParams)}
        options={{ ...options, roleList: roleOptions }}
      />
      <UploadModal
        {...uploadModal}
        onCancel={() => {
          setUploadModal({ visible: false })
        }}
        refresh={() => getData(searchParams)}
      />
      <StatusModal
        {...statusModal}
        onCancel={() => {
          setStatusModal({ visible: false, record: null })
        }}
        refresh={() => getData(searchParams)}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default Users
