import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import "@style/admin_panel.less"
import {
  AppraisalTemplate,
  AppraisalCycle,
  SupervisorTable,
  Users,
  CoreCompetencies,
  AdditionalScore,
  GoalSetting,
  SiteKPI,
  ApprovalMap,
  AttendanceRecord,
  Permission,
  Degree360SupervisorTable,
  RoleTable,
  AppraisalTarget,
  SiteAdmin,
  Comments,
  CoreCompetencySet,
  EmailTemplate,
  GoalSettingSet,
  DefaultApprover,
  ApprovalPath,
} from "@components/admin_table"
import { Menu, Spin, PageHeader } from "antd"
import { getPermission, login, isLoggedIn } from "@services/auth"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"

const AdminRoute = ({
  component: Component,
  location,
  permission,
  ...rest
}) => {
  if (!permission) {
    navigate("/")
    return null
  }
  return <Component {...rest} />
}

const DisplayTable = ({ permission }) => (
  <Router basepath="admin_panel">
    <AdminRoute
      path="approvalPath"
      component={ApprovalPath}
      permission={permission.template}
    />
    <AdminRoute
      path="template"
      component={AppraisalTemplate}
      permission={permission.template}
    />
    <AdminRoute
      path="coreCompetencySet"
      component={CoreCompetencySet}
      permission={permission.template}
    />
    <AdminRoute
      path="goalSettingSet"
      component={GoalSettingSet}
      permission={permission.template}
    />
    <AdminRoute
      path="coreCompetencies"
      component={CoreCompetencies}
      permission={permission.template}
    />
    <AdminRoute
      path="additionalScore"
      component={AdditionalScore}
      permission={permission.template}
    />
    <AdminRoute
      path="comments"
      component={Comments}
      permission={permission.template}
    />
    <AdminRoute
      path="goalSetting"
      component={GoalSetting}
      permission={permission.template}
    />
    <AdminRoute
      path="defaultApprover"
      component={DefaultApprover}
      permission={permission.user || permission.isSiteAdmin}
    />
    <AdminRoute
      path="appraisalCycle"
      component={AppraisalCycle}
      permission={permission.cycle}
    />
    <AdminRoute
      path="siteKPI"
      component={SiteKPI}
      permission={permission.mainten}
    />
    <AdminRoute
      component={SupervisorTable}
      path="supervisorTable"
      permission={permission.mainten || permission.isSiteAdmin}
    />
    <AdminRoute
      component={ApprovalMap}
      path="approvalMap"
      permission={permission.mainten}
    />
    <AdminRoute
      component={AttendanceRecord}
      path="attendanceRecord"
      permission={permission.mainten}
    />
    <AdminRoute
      component={Permission}
      path="permission"
      permission={permission.role}
    />
    <AdminRoute
      component={Degree360SupervisorTable}
      path="degree360Table"
      permission={permission.mainten}
    />
    <AdminRoute
      component={AppraisalTarget}
      path="target"
      permission={permission.mainten}
    />
    <AdminRoute
      component={SiteAdmin}
      path="siteAdmin"
      permission={permission.mainten}
    />
    <AdminRoute
      component={Users}
      path="users"
      permission={permission.user || permission.isSiteAdmin}
    />
    <AdminRoute
      component={RoleTable}
      path="roleTable"
      permission={permission.user}
    />
    <AdminRoute
      component={EmailTemplate}
      path="emailTemplate"
      permission={permission.mainten}
    />
  </Router>
)

const getTitle = str => {
  switch (str) {
    case "users":
      return <FormattedMessage id="users" />
    case "supervisorTable":
      return <FormattedMessage id="immediateSupervisor" />
    case "template":
      return "Appraisal Template"
    case "coreCompetencies":
      return "Core Competencies"
    case "additionalScore":
      return "Additional Score"
    case "goalSetting":
      return "Goal Setting"
    case "roleTable":
      return "Approval Role"
    case "appraisalCycle":
      return "Appraisal Cycle"
    case "permission":
      return "Role Permission"
    case "siteAdmin":
      return "Site Admin"
    case "target":
      return "Target Group"
    case "comments":
      return "Comments"
    case "attendanceRecord":
      return "Attendance Record Set"
    case "siteKPI":
      return "Site KPI"
    case "approvalMap":
      return "Approval Role Mapping"
    case "degree360Table":
      return "360° Evaluation Subordinate"
    case "emailTemplate":
      return "Email Template"
    case "coreCompetencySet":
      return "Core Competency Set"
    case "goalSettingSet":
      return "Goal Setting Set"
    case "defaultApprover":
      return <FormattedMessage id="approvalPath" />
    case "approvalPath":
      return "Default Approval Path"
    default:
      return ""
  }
}

const AdminPanel = ({ location }) => {
  const [isLoad, setIsLoad] = useState(false)
  const [editTemplate, setEditTemplate] = useState(false)
  const [editCycle, setEditCycle] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [editMainten, setEditMainten] = useState(false)
  const [isSiteAdmin, setIsSiteAdmin] = useState(false)
  const [editRole, setEditRole] = useState(false)
  const { pathname } = location

  const selectedKey = pathname.split("/")

  const showTitle = getTitle(selectedKey[2])

  const { SubMenu } = Menu

  const handleSelect = async ({ key }) => {
    let isLogin = await isLoggedIn()
    if (isLogin) {
      navigate("/admin_panel/" + key)
    } else {
      login()
    }
  }

  const checkPermission = item => {
    if (item === "AppraisalCycleTable") {
      setEditCycle(true)
    } else if (item === "AppraisalTemplate") {
      setEditTemplate(true)
    } else if (item === "Maintenance") {
      setEditMainten(true)
    } else if (item === "User") {
      setEditUser(true)
    } else if (item === "SiteAdmin") {
      setIsSiteAdmin(true)
    } else if (item === "Role") {
      setEditRole(true)
    }
  }

  const changePermission = arr => {
    if (arr === null) return null
    arr.forEach(item => {
      checkPermission(item)
    })

    return null
  }

  const editPermission = async () => {
    try {
      let res = await getPermission()
      let response = Array.isArray(res) ? [...res] : [res]
      changePermission(response)
      setIsLoad(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    editPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoad ? (
    <MainLayout>
      <Seo title="Admin Panel" />
      <Menu
        mode="horizontal"
        multiple={false}
        onSelect={handleSelect}
        selectedKeys={selectedKey}
        triggerSubMenuAction="click"
      >
        {editTemplate && (
          <SubMenu title="Template Configuration">
            <Menu.Item key="template">Appraisal Template List</Menu.Item>
            <Menu.Item key="coreCompetencySet">Core Competency Set</Menu.Item>
            <Menu.Item key="goalSettingSet">Goal Setting Set</Menu.Item>
            <Menu.Item key="approvalPath">Default Approval Path</Menu.Item>
          </SubMenu>
        )}

        {editTemplate && (
          <SubMenu title="Template Components">
            <Menu.Item key="coreCompetencies">Core Competencies</Menu.Item>
            <Menu.Item key="additionalScore">Additional Score</Menu.Item>
            <Menu.Item key="goalSetting">Goal Setting</Menu.Item>
            <Menu.Item key="comments">Comments</Menu.Item>
            <Menu.Item key="roleTable">Approval Role</Menu.Item>
          </SubMenu>
        )}

        {editCycle && (
          <Menu.Item key="appraisalCycle">Appraisal Cycle</Menu.Item>
        )}

        {isSiteAdmin && !editUser && (
          <Menu.Item key="supervisorTable">
            <FormattedMessage id="immediateSupervisor" />
          </Menu.Item>
        )}
        {(editUser || isSiteAdmin || editRole) && (
          <SubMenu title={<FormattedMessage id="users" />}>
            {(editUser || isSiteAdmin) && (
              <>
                <Menu.Item key="users">
                  <FormattedMessage id="userList" />
                </Menu.Item>
                <Menu.Item key="defaultApprover">
                  <FormattedMessage id="approvalPath" />
                </Menu.Item>
              </>
            )}
            {editRole && (
              <Menu.Item key="permission">Role Permission</Menu.Item>
            )}
          </SubMenu>
        )}
        {editMainten && (
          <SubMenu title="Maintenance">
            <Menu.Item key="siteAdmin">Site Admin</Menu.Item>

            {/* <Menu.Item key="target">Appraisal Target Group</Menu.Item> */}

            <Menu.Item key="attendanceRecord">Attendance Record Set</Menu.Item>

            <Menu.Item key="siteKPI">Site KPI</Menu.Item>

            <Menu.Item key="approvalMap">Approval Role Mapping</Menu.Item>

            <Menu.Item key="supervisorTable">
              <FormattedMessage id="immediateSupervisor" />
            </Menu.Item>

            <Menu.Item key="degree360Table">
              360° Evaluation Subordinate
            </Menu.Item>

            <Menu.Item key="emailTemplate">Email Template</Menu.Item>
          </SubMenu>
        )}
      </Menu>
      <PageHeader title={showTitle} backIcon={false} />
      <DisplayTable
        permission={{
          template: editTemplate,
          cycle: editCycle,
          user: editUser,
          mainten: editMainten,
          isSiteAdmin: isSiteAdmin,
          role: editRole,
        }}
      />
    </MainLayout>
  ) : (
    <Spin size="large" />
  )
}

export default AdminPanel
