import React from "react"
import { Button, Space, Form, Row, Col, DatePicker, Input } from "antd"
import { SwapRightOutlined } from "@ant-design/icons"
import { dataPickerProp } from "@object"

const SearchForm = ({ setSearchParams }) => {
  const [form] = Form.useForm()

  const onFinish = values => {
    let newValues = {
      ...values,
      appraisalStartDate: values.appraisalStartDate
        ? values.appraisalStartDate.toDate()
        : null,
      appraisalEndDate: values.appraisalEndDate
        ? values.appraisalEndDate.toDate()
        : null,
    }
    setSearchParams(newValues)
  }
  return (
    <Row gutter={24} className="admin_table">
      <Form layout="inline" form={form} onFinish={onFinish}>
        <Col span={12}>
          <Form.Item label="Cycle Code" name="code">
            <Input placeholder="Input a Cycle Code" allowClear />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Appraisal Period">
            <Input.Group compact>
              <Form.Item name="appraisalStartDate">
                <DatePicker
                  {...dataPickerProp}
                  allowClear
                  placeholder="Select Date From"
                />
              </Form.Item>
              <Form.Item>
                &nbsp; <SwapRightOutlined />
                &nbsp;
              </Form.Item>
              <Form.Item name="appraisalEndDate">
                <DatePicker
                  allowClear
                  placeholder="Select Date To"
                  {...dataPickerProp}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

export default SearchForm
