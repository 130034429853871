import React from "react"
import { Button, Space, Form, Row, Col, Select, Input, Divider } from "antd"
import { FormattedMessage, useIntl } from "react-intl"

const SearchForm = ({ oldSearchParams, setSearchParams, options }) => {
  const { Option } = Select

  const [form] = Form.useForm()
  const onFinish = values => {
    setSearchParams({
      ...oldSearchParams,
      staffNo: values.staffNo,
      staffName: values.staffName,
      supStaffNo: values.supStaffNo,
      supStaffName: values.supStaffName,
      companyIdList: values.companyIdList,
      siteIdList: values.siteIdList,
      positionIdList: values.positionIdList,
      roleNameList: values.roleNameList,
      pageIndex: 0,
    })
  }

  const { formatMessage } = useIntl()

  const staffNoLabel = formatMessage({ id: "staffNo" })
  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNoLabel }
  )

  const staffNameLabel = formatMessage({ id: "staffName" })
  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNameLabel }
  )

  const companyLabel = formatMessage({ id: "company" })
  const companyPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: companyLabel }
  )

  const siteLabel = formatMessage({ id: "site" })
  const sitePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: siteLabel }
  )

  const positionLabel = formatMessage({ id: "position" })
  const positionPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: positionLabel }
  )

  return (
    <Row gutter={24} className="admin_table">
      <Form form={form} layout="inline" onFinish={onFinish} preserve={true}>
        <Col span={24}>
          <Divider orientation="left">
            <FormattedMessage id="appraisee" />
          </Divider>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNoLabel} name="staffNo">
            <Input placeholder={staffNoPlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNameLabel} name="staffName">
            <Input placeholder={staffNamePlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={companyLabel} name="companyIdList" preserve={true}>
            <Select
              mode="multiple"
              showSearch
              placeholder={companyPlaceholder}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.companyList &&
                options.companyList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={siteLabel} name="siteIdList">
            <Select
              showSearch
              mode="multiple"
              placeholder={sitePlaceholder}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.siteOrDepartmentList &&
                options.siteOrDepartmentList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={positionLabel} name="positionIdList">
            <Select
              mode="multiple"
              showSearch
              placeholder={positionPlaceholder}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.positionList &&
                options.positionList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider orientation="left">
            <FormattedMessage id="supervisorDivider" />
          </Divider>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNoLabel} name="supStaffNo">
            <Input placeholder={staffNoLabel} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNameLabel} name="supStaffName">
            <Input placeholder={staffNameLabel} allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                <FormattedMessage id="search" />
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                <FormattedMessage id="clear" />
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

export default SearchForm
