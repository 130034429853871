import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input, message, Spin, Radio,InputNumber } from "antd"
import { EditOutlined, PlusOutlined, CopyOutlined } from "@ant-design/icons"
import { CommentDefinitionClient } from "@api"
import { showSuccess, showError, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, EnableButton, DeleteButton } from "lib"

const EditModal = ({ isNew, isCreate, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
        nameEN: record.name,
        nameZH: record.name_cn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)

    const newVales = {
      ...values,
      name: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.nameZH,
          },
          {
            cultureInfoId: "en-US",
            message: values.nameEN,
          },
        ],
      },
    }
    let client = new CommentDefinitionClient()
    try {
      if (isCreate) {
        await client.create({ ...newVales, isEnable: true })
      } else {
        await client.edit(record.id, {
          id: record.id,
          ...newVales,
          isEnabled: record.isEnable,
        })
      }
      showSuccess(isCreate ? "Add request success" : "Change request success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Comment" : "Change Comment", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Comment"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="nameEN"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a Title" />
        </Form.Item>

        <Form.Item
          name="nameZH"
          label="標題"
          rules={[{ required: true, message: "Please input a 標題 " }]}
        >
          <Input placeholder="輸入標題" />
        </Form.Item>

        <Form.Item
          name="order"
          label="Order"
          rules={[{ required: true, message: "Please input a order " }]}
        >
          <InputNumber placeholder="order" />
        </Form.Item>
        <Form.Item
          name="commentType"
          label="Comment Type"
          rules={[{ required: true, message: "Please select a type" }]}
        >
          <Radio.Group>
            <Radio value={0}>Text</Radio>

            <Radio value={1}>Datetime</Radio>

            <Radio value={2}>Attachment</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const Comments = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [modalVars, setModalVars] = useState({
    isCreate: false,
    isNew: false,
    record: null,
  })
  const [pageMeta, setPageMeta] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    isEnable: false,
    orderBy: "id",
    isAscend: true,
    pageSize: 10,
    pageIndex: 0,
  })

  const getData = async params => {
    setIsLoading(true)
    let client = new CommentDefinitionClient()
    try {
      let result = await client.get(
        params.isEnable,
        params.orderBy,
        params.isAscend,
        params.pageSize,
        params.pageIndex
      )
      setPageMeta(result.paginationMetadata)
      setDataSource(result.list)
      setIsLoaded(true)
    } catch {
      message.error("System Error !")
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new CommentDefinitionClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      getData(searchParams)
    } catch (err) {
      console.log(err)
    }
  }

  const changeAble = async record => {
    let client = new CommentDefinitionClient()
    try {
      await client.enableOrDisable(record.id, {
        id: record.id,
        isEnabled: !record.isEnable,
      })
      showSuccess(
        record.isEnable ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "標題",
      dataIndex: "name_cn",
      sorter: true,
    },
    {
      title: "Order",
      dataIndex: "order",
      sorter: true,
    },
    {
      title: "Comment Type ",
      dataIndex: "commentType",
      sorter: true,
      render: text =>
        text === 0
          ? "Text"
          : text === 1
          ? "Datetime"
          : text === 2
          ? "Attachment"
          : " - ",
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Button
              shape="round"
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isCreate: false,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Edit
            </Button>
            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isCreate: true,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Copy
            </Button>
            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnable}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]

  return isLoaded ? (
    <>
      <EditModal
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        isCreate={modalVars.isCreate}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isCreate: true,
              isNew: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default Comments
