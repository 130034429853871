import React, { useState, useEffect, useCallback } from "react"
import { Button, Space } from "antd"
import { PlusOutlined, CopyOutlined } from "@ant-design/icons"
import "../admin_table.less"
import { DefaultApprovalPathsClient } from "@api"
import { showError, showSuccess } from "@action"
import { PanelTable, Loading, DeleteButton, ViewButton } from "lib"
import EditModal from "./EditModal"
import { navigate } from "gatsby"

const ApprovalPath = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState(null)
  const [modalVars, setModalVars] = useState({
    isNew: true,
    isCreate: true,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    pageIndex: 1,
    pageSize: 10,
    orderBy: "id",
    isAscend: true,
    code: null,
    name: null,
  })

  const getData = useCallback(() => {
    const callAPi = async params => {
      setIsLoading(true)
      const client = new DefaultApprovalPathsClient()
      const result = await client
        .get(params.pageIndex, params.pageSize, params.orderBy, params.isAscend)
        .catch(err => {
          console.log(err)
          return { items: [] }
        })
      setDataSource(result.items)
      setPageMeta({
        currentPage: result.currentPage,
        totalCount: result.totalCount,
        pageSize: params.pageSize,
      })
      setIsLoaded(true)
      setIsLoading(false)
    }

    callAPi(searchParams)
  }, [searchParams])

  const deleteData = async id => {
    let client = new DefaultApprovalPathsClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      setSearchParams({
        ...searchParams,
        pageIndex: 1,
      })
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData()
  }, [getData])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    { title: "Code", dataIndex: "code", sorter: true },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <ViewButton
              onClick={() => {
                navigate("/configuration/approvalPath", {
                  state: { id: record.id },
                })
              }}
            />
            <Button
              icon={<CopyOutlined />}
              shape="round"
              size="small"
              onClick={() => {
                navigate("/configuration/approvalPath", {
                  state: { id: record.id, isCopy: true },
                })
              }}
            >
              Copy
            </Button>
            <DeleteButton
              onClick={() => {
                deleteData(record.id)
              }}
            />
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <Button
        style={{ marginBottom: "10px" }}
        shape="round"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate("/configuration/approvalPath")
        }}
      >
        Add
      </Button>

      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <EditModal
        visible={modalVars.visible}
        onCancel={() => {
          setModalVars({ visible: false })
        }}
      />
    </>
  ) : (
    <Loading />
  )
}

export default ApprovalPath
