import React, { useState, useEffect } from "react"
import {
  Button,
  Space,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Popconfirm,
} from "antd"
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
  CopyOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import JoditEditor from "jodit-react"
import { CoreCompetencyClient } from "@api"
import { showSuccess, showError, editorConfig, errorMessage } from "@action"
import Loading from "@components/Loading"
import FormModal from "@components/FormModal"
import { YesOrNoIcon, PanelTable } from "lib"

const EditModal = ({ isCreate, isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
        title: record.name,
        title_cn: record.name_cn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)

    const newValues = {
      ...values,
      title: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.title_cn,
          },
          {
            cultureInfoId: "en-US",
            message: values.title,
          },
        ],
      },
      description: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.description_cn,
          },
          {
            cultureInfoId: "en-US",
            message: values.description,
          },
        ],
      },
    }
    let client = new CoreCompetencyClient()
    try {
      if (isCreate) {
        await client.create(newValues)
      } else {
        await client.edit(record.id, { id: record.id, ...newValues })
      }

      showSuccess(
        isCreate
          ? "Add Core Competencies success"
          : "Edit Core Competencies success"
      )
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(
        isCreate ? "Add Core Competencies" : "Edit Core Competencies",
        err
      )
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Core Competencies"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a Title" />
        </Form.Item>

        <Form.Item
          name="title_cn"
          label="標題"
          rules={[{ required: true, message: "Please input a 標題" }]}
        >
          <Input placeholder="輸入標題" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please input a description" }]}
        >
          <JoditEditor
            config={editorConfig}
            tabIndex={1}
            placeholder="Input a description"
          />
        </Form.Item>
        <Form.Item
          name="description_cn"
          label="描述"
          rules={[{ required: true, message: "Please input a 描述" }]}
        >
          <JoditEditor
            config={editorConfig}
            tabIndex={1}
            placeholder="輸入描述"
          />
        </Form.Item>

        <Form.Item
          name="score"
          label="Score"
          rules={[{ required: true, message: "Please input a score" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="hasGoalsSet"
          label="Have Goal Setting?"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const CoreCompetencies = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })
  const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "id",
    isAscend: false,
  })

  const [pageMeta, setPageMeta] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    let client = new CoreCompetencyClient()
    try {
      let result = await client.get(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend
      )
      setPageMeta(result.content.paginationMetadata)
      setDataSource(result.content.coreCompetencyGroup)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new CoreCompetencyClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const changeAble = async data => {
    let client = new CoreCompetencyClient()
    try {
      await client.enableOrDisable({
        id: data.id,
        isEnabled: !data.isEnabled,
      })
      showSuccess(
        data.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "標題",
      dataIndex: "name_cn",
      sorter: true,
    },
    {
      title: "Descriptions",
      dataIndex: "description",
      sorter: true,
      width: "25%",
      render: text => (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      ),
    },

    {
      title: "描述",
      dataIndex: "description_cn",
      sorter: true,
      width: "25%",
      render: text => (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      ),
    },
    { title: "Score", dataIndex: "score", sorter: true, align: "center" },
    {
      title: "Have Goal Setting",
      dataIndex: "hasGoalsSet",
      sorter: true,
      align: "center",
      render: hasGoalsSet => <YesOrNoIcon value={hasGoalsSet} />,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            {record.isCanDelete && (
              <Button
                shape="round"
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setModalVars({
                    isNew: false,
                    isCreate: false,
                    record: record,
                  })
                  setVisible(true)
                }}
              >
                Edit
              </Button>
            )}
            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: true,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Copy
            </Button>
            {record.isCanDelete ? (
              <Popconfirm
                onConfirm={() => {
                  deleteData(record.id)
                }}
                okText="Delete"
                title="Sure to Delete?"
              >
                <Button shape="round" icon={<DeleteOutlined />} size="small">
                  Delete
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={
                  record.isEnabled ? "Sure to disable?" : "Sure to enable?"
                }
                onConfirm={() => changeAble(record)}
              >
                <Button
                  shape="round"
                  icon={
                    record.isEnabled ? <CloseOutlined /> : <CheckOutlined />
                  }
                  size="small"
                >
                  {record.isEnabled ? "Disable" : "Enable"}
                </Button>
              </Popconfirm>
            )}
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default CoreCompetencies
