import React, { useState, useEffect } from "react"
import { Button, Space, Spin } from "antd"
import { PlusOutlined, CopyOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { AppraisalTemplateClient } from "@api"
import { YesOrNoIcon } from "lib"
import { showSuccess, showError } from "@action"
import {
  PanelTable,
  EnableButton,
  DeleteButton,
  EditButton,
  ViewButton,
  TemplateTypeTag,
} from "lib"

const AppraisalTemplate = () => {
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "code",
    isAscend: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [pageMeta, setPageMeta] = useState(undefined)
  const [dataSource, setDataSource] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const getData = async params => {
    setIsLoading(true)
    const client = new AppraisalTemplateClient()
    const res = await client
      .get(
        false,
        null,
        params.pageIndex + 1,
        params.pageSize,
        params.orderBy,
        params.isAscend
      )
      .catch(err => {
        console.log(err)
        return { items: [] }
      })
    setDataSource(res.items)
    setPageMeta({
      currentPage: res.currentPage,
      totalCount: res.totalCount,
      pageSize: params.pageSize,
    })
    setIsLoad(true)
    setIsLoading(false)
  }

  const changeAble = async data => {
    let client = new AppraisalTemplateClient()
    try {
      await client.enableOrDisable({
        id: data.id,
        isEnabled: !data.isEnabled,
      })
      showSuccess(
        data.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const deleteData = async id => {
    let client = new AppraisalTemplateClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: 0,
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Template Id",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Template Code",
      dataIndex: "code",
      sorter: true,
    },
    {
      title: "Template",
      dataIndex: "name",
      render: (text, record) => record.id + ". " + text,
      sorter: true,
    },
    {
      title: "Template Type",
      dataIndex: "type",
      sorter: true,
      render: value => <TemplateTypeTag value={value} />,
    },
    {
      title: "Core Competency Weighting",
      dataIndex: "coreCompetencyWeighting",
      sorter: true,
    },
    {
      title: "Site KPI Weighting",
      dataIndex: "siteKPIWeighting",
      sorter: true,
    },
    {
      title: "Degree 360 Weighting",
      dataIndex: "degree360Weighting",
      sorter: true,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      render: value => <YesOrNoIcon value={value} />,
      sorter: true,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Link
              to="/configuration/template/"
              state={{ id: record.id, isCreate: false }}
            >
              <EditButton hidden={!record.isCanDelete} />
            </Link>

            <Link to="/configuration/viewTemplate/" state={{ id: record.id }}>
              <ViewButton />
            </Link>
            <Link
              to="/configuration/template/"
              state={{ id: record.id, isCreate: true }}
            >
              <Button shape="round" icon={<CopyOutlined />} size="small">
                Copy
              </Button>
            </Link>
            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]

  return isLoad ? (
    <>
      <Space size="small" className="table-Buttom">
        <Link to="/configuration/template/" state={{ isCreate: true }}>
          <Button shape="round" type="primary" icon={<PlusOutlined />}>
            Add
          </Button>
        </Link>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={value => {
          setSearchParams({
            ...searchParams,
            ...value,
          })
        }}
        defaultOrder={{
          orderBy: "code",
          isAscend: false,
        }}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default AppraisalTemplate
