import React, { useState, useEffect } from "react"
import { Form, InputNumber, Input } from "antd"
import { SupervisorClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"
import { useIntl } from "react-intl"

const EditModal = ({ isCreate, isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!isNew && record) {
      form.setFieldsValue({
        ...record,
      })
    } else {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    let client = new SupervisorClient()

    setIsLoading(true)
    try {
      if (isCreate) {
        await client.create(values)
      } else {
        await client.edit(record.id, { id: record.id, ...values })
      }
      showSuccess(
        isCreate ? "Add Supervisior success" : "Edit Supervisior success"
      )
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Supervisior" : "Edit Supervisior", err)
    } finally {
      setIsLoading(false)
    }
  }

  const { formatMessage } = useIntl()

  const supervisorStaffNoLabel = formatMessage({ id: "supervisorStaffNo" })
  const appraiseeStaffNoLabel = formatMessage({ id: "appraiseeStaffNo" })

  const appraieeStaffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: appraiseeStaffNoLabel }
  )

  const supervisorStaffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: supervisorStaffNoLabel }
  )
  const title = formatMessage({ id: "supervisorDivider" })
  const supervisorLevelLabel = formatMessage({ id: "supervisorLevel" })

  return (
    <FormModal
      isTranslate
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={title}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="appraiseeStaffNo"
          label={appraiseeStaffNoLabel}
          rules={[{ required: true }]}
        >
          <Input placeholder={appraieeStaffNoPlaceholder} />
        </Form.Item>

        <Form.Item
          name="supervisorStaffNo"
          label={supervisorStaffNoLabel}
          rules={[{ required: true }]}
        >
          <Input placeholder={supervisorStaffNoPlaceholder} />
        </Form.Item>
        <Form.Item
          name="level"
          label={supervisorLevelLabel}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
