import React, { useState, useEffect } from "react"
import { Form, Checkbox } from "antd"
import { StaffDefaultApproversClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"
import { FormattedMessage, useIntl } from "react-intl"

const EditModal = ({ visible, onCancel, record, refresh, options = [] }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        approvalRoleIds: record.staffDefaultApprovers.map(
          approver => approver.id
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    try {
      const client = new StaffDefaultApproversClient()
      await client.update(record.staffNo, {
        staffId: record.staffNo,
        ...values,
      })
      showSuccess("Edit Request Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Edit User", err)
    } finally {
      setIsLoading(false)
    }
  }

  const { formatMessage } = useIntl()
  const title = formatMessage({ id: "defaultApprovers" })

  return (
    <FormModal
      isTranslate
      isCreate={false}
      form={form}
      onCancel={onCancel}
      title={title}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish} layout="vertical">
        <Form.Item
          label={<FormattedMessage id="approvalRole" />}
          name="approvalRoleIds"
        >
          <Checkbox.Group options={options} />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
