import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input, InputNumber, Spin, Checkbox } from "antd"
import {
  EditOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons"
import JoditEditor from "jodit-react"
import { ScoreAdjustmentClient } from "@api"
import {
  translate,
  showSuccess,
  showError,
  editorConfig,
  errorMessage,
} from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, DeleteButton, EnableButton, YesOrNoIcon } from "lib"

const transformLabel = arr => {
  return arr.map(item => {
    return {
      label: translate(item.name, "en-US"),
      label_cn: translate(item.name, "zh-HK"),
      score: item.score,
    }
  })
}

const EditModal = ({ isCreate, isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
        title: record.name,
        title_cn: record.name_cn,
        labels:
          record && record.scoreAdjustmentOptionGroup
            ? transformLabel(record.scoreAdjustmentOptionGroup)
            : [],
      })
    } else {
      form.setFieldsValue({
        labels: [{ label: "", label_cn: "", score: "" }],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new ScoreAdjustmentClient()
    const newValues = {
      ...values,
      title: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.title_cn,
          },
          {
            cultureInfoId: "en-US",
            message: values.title,
          },
        ],
      },
      description: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.description_cn,
          },
          {
            cultureInfoId: "en-US",
            message: values.description,
          },
        ],
      },

      labels: values.labels.map(label => ({
        label: {
          translations: [
            {
              cultureInfoId: "zh-HK",
              message: label.label_cn,
            },
            {
              cultureInfoId: "en-US",
              message: label.label,
            },
          ],
        },
        score: label.score,
      })),
    }

    try {
      if (isCreate) {
        await client.create(newValues)
      } else {
        await client.edit(record.id, { id: record.id, ...newValues })
      }
      showSuccess(isCreate ? "Add Request Success" : "Change Request Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(
        isCreate ? "Change Additional Score" : "Change Additional Score",
        err
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Additional Score"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a Title" />
        </Form.Item>

        <Form.Item
          name="title_cn"
          label="標題"
          rules={[{ required: true, message: "Please input a 標題" }]}
        >
          <Input placeholder="輸入標題" />
        </Form.Item>

        {typeof window !== undefined && (
          <>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please input a description" },
              ]}
            >
              <JoditEditor
                config={{ ...editorConfig, height: 300 }}
                tabIndex={0}
                placeholder="Input a description"
              />
            </Form.Item>
            <Form.Item
              name="description_cn"
              label="描述"
              rules={[{ required: true, message: "Please input a 描述" }]}
            >
              <JoditEditor
                config={{ ...editorConfig, height: 300 }}
                tabIndex={0}
                placeholder="輸入描述"
              />
            </Form.Item>
          </>
        )}
        <Form.Item label="Range of score" required>
          <Input.Group compact>
            <Form.Item
              name="minScore"
              rules={[{ required: true, message: "Please input a score" }]}
            >
              <InputNumber />
            </Form.Item>
            {" - "}
            <Form.Item
              name="maxScore"
              rules={[{ required: true, message: "Please input a score" }]}
            >
              <InputNumber />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          name="maxItems"
          label="Maximum Number of Items"
          rules={[{ required: true, message: "Please input a number" }]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          name="isNegativeScore"
          label="Is Negative"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.List name="labels">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(field => (
                  <Space
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="center"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "label"]}
                      label="Label"
                      rules={[
                        { required: true, message: "Please input a label" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "label_cn"]}
                      label="標籤"
                      rules={[
                        { required: true, message: "Please input a 標籤" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "score"]}
                      label="Score"
                      rules={[
                        { required: true, message: "Please input a score" },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name)
                        }}
                      />
                    )}
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add()
                    }}
                    block
                  >
                    <PlusOutlined /> Add Label
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>
      </Form>
    </FormModal>
  )
}

const AdditionalScore = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })
  const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "name",
    isAscend: true,
  })

  const [pageMeta, setPageMeta] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    let client = new ScoreAdjustmentClient()
    try {
      let result = await client.get(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend
      )
      setPageMeta(result.content.paginationMetadata)
      setDataSource(result.content.scoreAdjustmentGroup)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new ScoreAdjustmentClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }
  const changeAble = async data => {
    let client = new ScoreAdjustmentClient()
    try {
      await client.enableOrDisable({
        id: data.id,
        isEnabled: !data.isEnabled,
      })
      showSuccess(
        data.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "標題",
      dataIndex: "name_cn",
      sorter: true,
    },
    {
      title: "Descriptions",
      dataIndex: "description",
      sorter: true,
      render: text => (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      ),
    },
    {
      title: "描述",
      dataIndex: "description_cn",
      sorter: true,
      render: text => (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      ),
    },
    {
      title: "Range of Score",
      render: (_, record) => (
        <>
          {record.minScore} - {record.maxScore}
        </>
      ),
    },
    { title: "Maximum Number of Items", dataIndex: "maxItems", sorter: true },
    {
      title: "Is Negative",
      dataIndex: "isNegativeScore",
      sorter: true,
      render: value => <YesOrNoIcon value={value} />,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            {record.isCanDelete && (
              <Button
                shape="round"
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setModalVars({
                    isNew: false,
                    isCreate: false,
                    record: record,
                  })
                  setVisible(true)
                }}
              >
                Edit
              </Button>
            )}
            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: true,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Copy
            </Button>
            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={value =>
          setSearchParams({ ...searchParams, ...value })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default AdditionalScore
