import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input, Spin } from "antd"
import {
  PlusOutlined,
  MinusCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons"
import "../admin_table.less"
import { GoalSettingClient } from "@api"
import { translate, showSuccess, showError, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, EnableButton, DeleteButton, EditButton } from "lib"

const transformLabel = arr => {
  return arr.map(item => {
    return {
      label: translate(item.name, "en-US"),
      label_cn: translate(item.name, "zh-HK"),
      score: item.score,
    }
  })
}

const EditModal = ({ isCreate, isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
        title: record.name,
        title_cn: record.name_cn,
        labels:
          record && record.goalSettingPlansGroup
            ? transformLabel(record.goalSettingPlansGroup)
            : [],
      })
    } else {
      form.setFieldsValue({
        labels: [{ label: "", label_cn: "" }],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    const newValues = {
      title: {
        translations: [
          {
            cultureInfoId: "zh-HK",
            message: values.title_cn,
          },
          {
            cultureInfoId: "en-US",
            message: values.title,
          },
        ],
      },
      labels: values.labels.map(label => ({
        label: {
          translations: [
            {
              cultureInfoId: "zh-HK",
              message: label.label_cn,
            },
            {
              cultureInfoId: "en-US",
              message: label.label,
            },
          ],
        },
      })),
    }
    try {
      let client = new GoalSettingClient()
      if (isCreate) {
        await client.create(newValues)
      } else {
        await client.edit(record.id, { id: record.id, ...newValues })
      }
      showSuccess(isCreate ? "Add Request Success" : "Change Request Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Goal Setting" : "Edit Goal Setting", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Goal Setting"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a Title" />
        </Form.Item>

        <Form.Item
          name="title_cn"
          label="標題"
          rules={[{ required: true, message: "Please input a 標題" }]}
        >
          <Input placeholder="輸入標題" />
        </Form.Item>
        <Form.List name="labels">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(field => (
                  <Space
                    key={field.fieldkey}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="center"
                  >
                    <Form.Item
                      {...field}
                      fieldKey={[field.fieldKey, "first"]}
                      name={[field.name, "label"]}
                      label="Plan Name"
                      rules={[
                        { required: true, message: "Please input a Plan" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      fieldKey={[field.fieldKey, "last"]}
                      name={[field.name, "label_cn"]}
                      label="計劃名稱"
                      rules={[
                        { required: true, message: "Please input a 計劃" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name)
                        }}
                      />
                    )}
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add()
                    }}
                    block
                  >
                    <PlusOutlined /> Add Label
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>
      </Form>
    </FormModal>
  )
}

const GoalSetting = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })
  const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "name",
    isAscend: true,
  })
  const [pageMeta, setPageMeta] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    let client = new GoalSettingClient()
    try {
      let result = await client.get(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend
      )
      setPageMeta(result.content.paginationMetadata)
      setDataSource(result.content.goalSettingGroup)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new GoalSettingClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }
  const changeAble = async data => {
    let client = new GoalSettingClient()
    try {
      await client.enableOrDisable({
        id: data.id,
        isEnabled: !data.isEnabled,
      })
      showSuccess(
        data.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "標題",
      dataIndex: "name_cn",
      sorter: true,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            {record.isCanDelete && (
              <EditButton
                onClick={() => {
                  setModalVars({
                    isNew: false,
                    isCreate: false,
                    record: record,
                  })
                  setVisible(true)
                }}
              />
            )}
            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: true,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Copy
            </Button>

            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <Space className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default GoalSetting
