import React, { useState } from "react"
import { Modal, Upload, Form, Checkbox, Button, message } from "antd"
import { UsersClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { UploadOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"

const UploadModal = ({ visible, onCancel, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()

  const uploadLabel = formatMessage({ id: "upload" }, { name: "" })
  const cancelLabel = formatMessage({ id: "cancel" })
  const fileLabel = formatMessage({ id: "file" })
  const fileButton = formatMessage(
    { id: "selectPlaceholder" },
    { name: fileLabel }
  )
  const userLabel = formatMessage({ id: "user" })
  const excelLabel = formatMessage({ id: "excel" })
  const disableOtherUsersLabel = formatMessage({ id: "disableOtherUsers" })

  const normFile = ({ fileList }) => {
    if (fileList.length) {
      const file = fileList[0]
      if (file.size / 1024 / 1024 > 10) {
        message.error("檔案不能超過10 Mbs")
        return null
      }
      return { data: file.originFileObj, fileName: file.name }
    }
    return null
  }

  const uploadProps = {
    maxCount: 1,
    beforeUpload: () => false,
    accept:
      "application/vnd.ms-excel,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  }

  const onFinish = async values => {
    try {
      setIsLoading(true)
      const client = new UsersClient()
      await client.upload(values.disableOtherUsers, values.file)
      showSuccess("Upload " + values.file.fileName + " Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Upload ", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      forceRender
      title={`${uploadLabel} ${userLabel} ${excelLabel}`}
      destroyOnClose={true}
      confirmLoading={isLoading}
      visible={visible}
      okText={uploadLabel}
      cancelText={cancelLabel}
      onCancel={() => {
        if (!isLoading) {
          form.resetFields()
          onCancel()
        }
      }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          initialValue={false}
          name="disableOtherUsers"
          label={disableOtherUsersLabel}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          valuePropName="file"
          name="file"
          label={fileLabel}
          getValueFromEvent={normFile}
          rules={[{ required: true }]}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>{fileButton}</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UploadModal
