import React from "react"
import { Button, Space, Form, Row, Col, Select, Input } from "antd"
import { showSelectProps } from "@object"
import { FormattedMessage, useIntl } from "react-intl"

const SearchForm = ({
  oldSearchParams,
  setSearchParams,
  options,
  editUser,
  editRole,
}) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const selectProps = { ...showSelectProps, allowClear: true, mode: "multiple" }
  const onFinish = values => {
    setSearchParams({
      ...oldSearchParams,
      staffNo: values.staffNo,
      staffName: values.staffName,
      companyIdList: values.companyIdList,
      siteIdList: values.siteIdList,
      positionIdList: values.positionIdList,
      roleNameList: values.roleNameList,
      pageIndex: 0,
    })
  }

  const { formatMessage } = useIntl()

  const staffNoLabel = formatMessage({ id: "staffNo" })
  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNoLabel }
  )

  const staffNameLabel = formatMessage({ id: "staffName" })
  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNameLabel }
  )

  const companyLabel = formatMessage({ id: "company" })
  const companyPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: companyLabel }
  )

  const siteLabel = formatMessage({ id: "site" })
  const sitePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: siteLabel }
  )

  const positionLabel = formatMessage({ id: "position" })
  const positionPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: positionLabel }
  )

  const roleLabel = formatMessage({ id: "role" })
  const rolePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: roleLabel }
  )

  return (
    <Row gutter={24} className="admin_table">
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Col span={12}>
          <Form.Item label={staffNoLabel} name="staffNo" preserve={true}>
            <Input placeholder={staffNoPlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNameLabel} name="staffName" preserve={true}>
            <Input placeholder={staffNamePlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={companyLabel} name="companyIdList" preserve={true}>
            <Select placeholder={companyPlaceholder} {...selectProps}>
              {options.companyList &&
                options.companyList.map(item => (
                  <Option id={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={siteLabel} name="siteIdList">
            <Select placeholder={sitePlaceholder} {...selectProps}>
              {options.siteOrDepartmentList &&
                options.siteOrDepartmentList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={positionLabel} name="positionIdList">
            <Select {...selectProps} placeholder={positionPlaceholder}>
              {options.positionList &&
                options.positionList.map(item => (
                  <Option id={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={editRole ? 12 : 0}>
          <Form.Item label={roleLabel} name="roleNameList">
            <Select placeholder={rolePlaceholder} {...selectProps}>
              {options.roleOptions &&
                options.roleOptions.map(item => (
                  <Option id={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Form.Item>
                <Button shape="round" type="primary" htmlType="submit">
                  <FormattedMessage id="search" />
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  onClick={() => {
                    form.resetFields()
                    form.submit()
                  }}
                >
                  <FormattedMessage id="clear" />
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

export default SearchForm
