import React, { useState, useEffect } from "react"
import { Form, Select, Input } from "antd"
import { UsersClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"
import { useIntl } from "react-intl"

const StatusModal = ({ visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()
  const statusLabel = formatMessage({ id: "status" })

  const statusPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: statusLabel }
  )
  const remarksLabel = formatMessage({ id: "disableUserReason" })
  const remarksPlaceholder = formatMessage({ id: "disableUserPlaceholder" })

  const inactiveRemarks = formatMessage({ id: "disableUserRemarkInactive" })
  const terminatedRemarks = formatMessage({ id: "disableUserRemarkTerminated" })

  const status = {
    inactive: "inactive",
    terminated: "terminated",
  }

  useEffect(() => {
    if (visible) form.setFieldsValue(record)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    const client = new UsersClient()
    try {
      await client.enableOrDisable({
        staffNo: record.staffNo,
        isEnabled: values.status === status.inactive,
        disableRemarks:
          values.status === status.inactive ? values.disableRemarks : null,
      })
      showSuccess("Disable User Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Disable User", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isTranslate
      form={form}
      hiddenPrefix
      onCancel={onCancel}
      title={remarksLabel}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          label={statusLabel}
          name="status"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={statusPlaceholder}
            options={Object.values(status).map(item => ({
              label: formatMessage({ id: item }),
              value: item,
            }))}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(preValue, currValue) =>
            preValue.status !== currValue.status
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("status") === status.inactive ? (
              <Form.Item
                label={remarksLabel}
                name="disableRemarks"
                rules={[{ required: true, whitespace: true }]}
              >
                <Input placeholder={remarksPlaceholder} maxLength={20} />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item>
          <div>{inactiveRemarks}</div>
          <div>{terminatedRemarks}</div>
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default StatusModal
