import React, { useState, useEffect } from "react"
import { Form, Modal, Checkbox, InputNumber } from "antd"
import { SupervisorClient } from "@api"
import { useIntl } from "react-intl"

const GenerModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const { formatMessage } = useIntl()

  const generateRandomSubordinateLabel = formatMessage({
    id: "generateRandomSubordinate",
  })
  const subordinateNumberLabel = formatMessage({ id: "subordinateNumber" })
  const isPaddingLabel = formatMessage({ id: "isPadding" })

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    let client = new SupervisorClient()
    setIsLoading(true)
    try {
      let res = await client.randomSubordinate(
        values.subordinateNumber,
        values.isPadding
      )
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement("a")
      a.href = url
      a.download = res.fileName
      a.click()
      a.remove()
      onCancel()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      forceRender
      visible={visible}
      title={generateRandomSubordinateLabel}
      confirmLoading={isLoading}
      destroyOnClose={true}
      onOk={() => form.submit()}
      onCancel={
        !isLoading
          ? onCancel
          : () => {
              console.log("Loading")
            }
      }
    >
      <Form form={form} onFinish={onFinish} preserve={true}>
        <Form.Item
          name="subordinateNumber"
          label={subordinateNumberLabel}
          rules={[{ required: true }]}
        >
          <InputNumber type="number" min={1} />
        </Form.Item>
        <Form.Item
          name="isPadding"
          label={isPaddingLabel}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default GenerModal
