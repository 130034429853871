import React, { useState, useEffect } from "react"
import { Button, Space, Form, Row, Col, Input, Select } from "antd"
import { OtherClient } from "@api"
import { showSelectProps } from "@object"
import { showError } from "@action"
import { useIntl } from "react-intl"

const SearchForm = ({ oldSearchParams, setSearchParams }) => {
  const [form] = Form.useForm()
  const { Option } = Select
  const [options, setOptions] = useState({ positionList: [] })
  const selectProps = { ...showSelectProps, allowClear: true, mode: "multiple" }
  const onFinish = values => {
    setSearchParams({
      ...oldSearchParams,
      staffNo: values.staffNo,
      staffName: values.staffName,
      companyIdList: values.companyIdList,
      siteIdList: values.siteIdList,
      positionIdList: values.positionIdList,
      roleNameList: values.roleNameList,
      pageIndex: 0,
    })
  }

  useEffect(() => {
    const getOption = async () => {
      try {
        const client = new OtherClient()
        const result = await client.get()
        setOptions(result.content)
      } catch (err) {
        let errmsg = JSON.parse(err.response)
        showError(errmsg.errors)
      }
    }
    getOption()
  }, [])
  const { formatMessage } = useIntl()

  const searchLabel = formatMessage({ id: "search" })
  const clearLabel = formatMessage({ id: "clear" })

  const staffNoLabel = formatMessage({ id: "staffNo" })
  const staffNoPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNoLabel }
  )

  const staffNameLabel = formatMessage({ id: "staffName" })
  const staffNamePlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    { name: staffNameLabel }
  )

  const positionLabel = formatMessage({ id: "position" })
  const positionPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: positionLabel }
  )

  return (
    <Row gutter={24} className="admin_table">
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Col span={12}>
          <Form.Item label={staffNoLabel} name="staffNo" preserve={true}>
            <Input placeholder={staffNoPlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={staffNameLabel} name="staffName" preserve={true}>
            <Input placeholder={staffNamePlaceholder} allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={positionLabel} name="positionIdList">
            <Select {...selectProps} placeholder={positionPlaceholder}>
              {options.positionList.map(item => (
                <Option id={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Form.Item>
                <Button shape="round" type="primary" htmlType="submit">
                  {searchLabel}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  onClick={() => {
                    form.resetFields()
                    form.submit()
                  }}
                >
                  {clearLabel}
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

export default SearchForm
