import React, { useState, useEffect } from "react"
import { Form, Input, Checkbox, InputNumber } from "antd"
import { ApprovalRoleClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"

const EditModal = ({ isCreate, isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        roleName: record && record.name ? record.name : null,
        ...record,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new ApprovalRoleClient()
    try {
      if (isCreate) {
        await client.create(values)
      } else {
        await client.edit(record.id, { id: record.id, ...values })
      }
      showSuccess(
        isCreate ? "Add Approval Role Success" : "Edit Approval Role Success"
      )
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Approval Role" : "Edit Approval Role", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title="Approval Role"
      isLoading={isLoading}
      visible={visible}
    >
      <Form preserve={false} form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="roleName"
          label="Role Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input a name",
            },
          ]}
        >
          <Input placeholder="Input a Role Name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Role Code"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input a Code",
            },
          ]}
        >
          <Input placeholder="Input a Role Code" />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[
            {
              required: true,
              message: "Please input an order",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input a type",
            },
          ]}
        >
          <Input placeholder="Input a Type" />
        </Form.Item>
        <Form.Item
          name="hasReportFilter"
          label="Shown in Progress Report"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="hasNotification"
          label="Notification Email"
          tooltip={{
            title:
              "Reminder to be sent to the roles that their subordinates’ appraisal case is pending for their action",
          }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="hasFrontline"
          label="Involved in Frontline Approval Path"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="isEnabled"
          label="Enabled"
          tooltip={{ title: "Roles are actively using in Approval Path" }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
