import React, { useState, useEffect } from "react"
import { Form, Select, Input, Modal } from "antd"
import { UsersClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormattedMessage, useIntl } from "react-intl"

const ResetModal = ({ visible, onCancel, staffNo }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()
  const passwordLabel = formatMessage({ id: "password" })
  const passwordPlaceholder = formatMessage(
    { id: "inputPlaceholder" },
    {
      name: passwordLabel,
    }
  )
  const passwordWarning = formatMessage(
    { id: "pleaseInput" },
    { name: passwordLabel }
  )

  const defaultPassword = formatMessage({ id: "defaultPassword" })

  const pwPattern = formatMessage({ id: "pwPattern" })

  const onFinish = async values => {
    setIsLoading(true)
    let client = new UsersClient()
    try {
      let result = await client.resetPassword({
        ...values,
        staffNo: staffNo,
      })
      if (result.isSucceeded) {
        showSuccess("Reset Password Success")
        onCancel()
      }
      if (result.errors && result.errors.length > 0) {
        throw result.errors
      }
    } catch (err) {
      errorMessage("Reset Password", err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (visible) form.resetFields()
  }, [visible])

  return (
    <Modal
      title={<FormattedMessage id="changePW" />}
      visible={visible}
      okText={<FormattedMessage id="changePW" />}
      onCancel={onCancel}
      onOk={() => form.submit()}
      forceRender
      destroyOnClose={true}
      confirmLoading={isLoading}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item label={passwordLabel} style={{ marginBottom: "0px" }}>
          <Input.Group compact>
            <Form.Item
              name="isDefault"
              style={{ marginRight: "8px" }}
              initialValue={true}
            >
              <Select>
                <Option value={true}>
                  <FormattedMessage id="default" />
                </Option>
                <Option value={false}>
                  <FormattedMessage id="custom" />
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isDefault !== currentValues.isDefault
              }
            >
              {({ getFieldValue }) =>
                !getFieldValue("isDefault") ? (
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: passwordWarning },
                      {
                        pattern:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                        message: pwPattern,
                      },
                    ]}
                  >
                    <Input.Password placeholder={passwordPlaceholder} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.isDefault !== currentValues.isDefault
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("isDefault") ? defaultPassword : pwPattern
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ResetModal
