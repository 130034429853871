import React, { useState, useEffect, useCallback } from "react"
import { Button, Space } from "antd"
import { PlusOutlined, CopyOutlined } from "@ant-design/icons"
import "../admin_table.less"
import { ApprovalRoleClient } from "@api"
import { showSuccess, showError } from "@action"
import {
  PanelTable,
  DeleteButton,
  EditButton,
  Loading,
  YesOrNoIcon,
  TooltipIcon,
} from "lib"
import EditModal from "./EditModal"

const RoleTable = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    visible: false,
    record: null,
    isCreate: false,
    isNew: false,
  })
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "name",
    isAscend: true,
  })
  const [pageMeta, setPageMeta] = useState(null)

  const getData = useCallback(() => {
    const callAPI = async params => {
      setIsLoading(true)
      let client = new ApprovalRoleClient()
      try {
        let result = await client.get(
          params.pageSize,
          params.pageIndex,
          params.orderBy,
          params.isAscend
        )
        setPageMeta(result.content.paginationMetadata)
        setDataSource(result.content.approvalRoleGroup)
        setIsLoaded(true)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    callAPI(searchParams)
  }, [searchParams])

  const deleteData = async id => {
    let client = new ApprovalRoleClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: 0,
        })
      } else {
        getData()
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData()
  }, [getData])

  const columns = [
    { title: "Role Name", dataIndex: "name", sorter: true },
    { title: "Role Code", dataIndex: "code", sorter: true },
    {
      title: (
        <div>
          Order&nbsp;
          <TooltipIcon title="For Default Approver Sequence" />
        </div>
      ),
      dataIndex: "order",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
    },
    {
      title: "Shown in Progress Report",
      dataIndex: "hasReportFilter",
      render: value => <YesOrNoIcon value={value} />,
    },

    {
      title: (
        <div>
          Notification Email&nbsp;
          <TooltipIcon title="Reminder to be sent to the roles that their subordinates’ appraisal case is pending for their action" />
        </div>
      ),
      dataIndex: "hasNotification",
      render: value => <YesOrNoIcon value={value} />,
    },
    {
      title: "Involved in Frontline Approval Path",
      dataIndex: "hasFrontline",
      render: value => <YesOrNoIcon value={value} />,
    },
    {
      title: (
        <div>
          Enabled&nbsp;
          <TooltipIcon title="Roles are actively using in Approval Path" />
        </div>
      ),
      dataIndex: "isEnabled",
      render: value => <YesOrNoIcon value={value} />,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  visible: true,
                  isNew: false,
                  isCreate: false,
                  record: record,
                })
              }}
            />
            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isNew: false,
                  visible: true,
                  isCreate: true,
                  record: record,
                })
              }}
            >
              Copy
            </Button>
            {record.isCanDelete && (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        {...modalVars}
        refresh={() => {
          getData()
        }}
        onCancel={() => {
          setModalVars({
            isNew: false,
            visible: false,
            isCreate: false,
            record: null,
          })
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              visible: true,
              isNew: true,
              isCreate: true,
              record: null,
            })
          }}
        >
          Add
        </Button>
      </Space>

      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default RoleTable
