import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input, Checkbox, Row, Col, Select } from "antd"
import { PlusOutlined, CopyOutlined } from "@ant-design/icons"
import { RolesClient, OtherClient } from "@api"
import { showSuccess, showError, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import {
  PanelTable,
  Loading,
  DeleteButton,
  EditButton,
  EnableButton,
} from "lib"
import { YesOrNoIcon } from "lib"

const generateArrar = arr => {
  let newArr = []
  arr.forEach(item => {
    switch (item.value) {
      case "Search":
        newArr.push(0)
        break
      case "AppraisalTemplate":
        newArr.push(1)
        break
      case "AppraisalCycleTable":
        newArr.push(2)
        break
      case "User":
        newArr.push(3)
        break
      case "Maintenance":
        newArr.push(4)
        break
      case "Report":
        newArr.push(5)
        break
      case "Role":
        newArr.push(6)
        break
      default:
        break
    }
  })
  return newArr
}

const EditModal = ({
  options,
  isCreate,
  isNew,
  visible,
  onCancel,
  record,
  refresh,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { Option } = Select

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        roleName: record && record.name ? record.name : [],
        roleClaims:
          record && record.roleClaim ? generateArrar(record.roleClaim) : [],
        searchExceptionSite: record.searchExceptionSites
          ? record.searchExceptionSites.map(item => item.siteOrDepartmentId)
          : [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new RolesClient()
    try {
      if (isCreate) {
        await client.create({
          ...values,
          roleDescription: values.roleName,
        })
      } else {
        await client.edit(record.id, {
          ...values,
          id: record.id,
          name: values.roleName,
          roleDescription: values.roleName,
        })
      }

      showSuccess(isCreate ? "Add Role Success" : "Edit Role Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Role" : "Edit Role", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Role permission"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" preserve={false} onFinish={onFinish} form={form}>
        <Form.Item
          name="roleName"
          label="Role Name"
          rules={[{ required: true, message: "Please input value" }]}
        >
          <Input placeholder="Input a Role Name" />
        </Form.Item>
        <Form.Item label="Permission" name="roleClaims">
          <Checkbox.Group>
            <Row>
              <Col>
                <Checkbox value={0}>Search</Checkbox>
              </Col>
              <Col>
                <Checkbox value={1}>Appraisal Template</Checkbox>
              </Col>
              <Col>
                <Checkbox value={2}>Appraisal Cycle Table</Checkbox>
              </Col>
              <Col>
                <Checkbox value={3}>User</Checkbox>
              </Col>
              <Col>
                <Checkbox value={4}>Maintenance</Checkbox>
              </Col>
              <Col>
                <Checkbox value={5}>Report</Checkbox>
              </Col>
              <Col>
                <Checkbox value={6}>Role</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.roleClaims !== curValues.roleClaims
          }
        >
          {({ getFieldValue }) => {
            let arr = getFieldValue("roleClaims")
            let hvSearch = Array.isArray(arr)
              ? arr.includes(0) || arr.includes(5)
              : false

            return hvSearch ? (
              <Form.Item
                label="Search / Reports Exception Site"
                name="searchExceptionSite"
              >
                <Select
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  mode="multiple"
                >
                  {options.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null
          }}
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const Permission = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageMeta, setPageMeta] = useState(undefined)
  const [options, setOptions] = useState([])
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "name",
    isAscend: true,
  })
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })

  const IsEnableIcon = ({ arr, str }) => (
    <YesOrNoIcon value={arr.findIndex(o => o.value === str) !== -1} />
  )

  const getData = async params => {
    setIsLoading(true)
    let client = new RolesClient()
    try {
      let result = await client.get(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend
      )
      setDataSource(result.content.roleGroup)
      setPageMeta(result.content.paginationMetadata)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new RolesClient()
    try {
      console.log(id)
      await client.delete(id)
      showSuccess("Delete Item Success")
      setSearchParams({
        ...searchParams,
        pageIndex: 0,
      })
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      showError(errmsg.errors)
    }
  }

  const changeAble = async data => {
    let client = new RolesClient()
    try {
      await client.enableOrDisable({
        id: data.id,
        isEnabled: !data.isEnabled,
      })
      showSuccess(
        data.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      showError(errmsg.errors)
    }
  }

  const getOptions = async () => {
    let client = new OtherClient()
    try {
      let result = await client.get()
      setOptions(result.content.siteOrDepartmentList)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Search",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"Search"} />
      ),
    },
    {
      title: "Appraisal Template",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"AppraisalTemplate"} />
      ),
    },
    {
      title: "Appraisal Cycle Table",
      dataIndex: "cycleEditable",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"AppraisalCycleTable"} />
      ),
    },
    {
      title: "User",
      dataIndex: "userEditable",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"User"} />
      ),
    },
    {
      title: "Maintenance",
      dataIndex: "maintenanceAble",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"Maintenance"} />
      ),
    },
    {
      title: "Report",
      dataIndex: "report",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"Report"} />
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      render: (_, record) => (
        <IsEnableIcon arr={record.roleClaim} str={"Role"} />
      ),
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: false,
                  record: record,
                })
                setVisible(true)
              }}
            />

            <Button
              shape="round"
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: true,
                  record: record,
                })
                setVisible(true)
              }}
            >
              Copy
            </Button>
            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        options={options}
        isNew={modalVars.isNew}
        record={modalVars.record}
        isCreate={modalVars.isCreate}
        refresh={() => {
          getData(searchParams)
        }}
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default Permission
