import React, { useEffect, useReducer, useCallback } from "react"
import { Button, Space } from "antd"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import "../admin_table.less"
import {
  AppraisalCycleClient,
  Degree360SupervisorSetClient,
  SiteKPISetClient,
  AttendanceRecordFileClient,
} from "@api"
import { transformDate } from "@action"
import { PanelTable } from "lib"
import SearchForm from "./SearchForm"
import { navigate } from "gatsby"
import EditModal from "./EditModal"

const AppraisalCycle = () => {
  const initialState = {
    dataSource: [],
    isLoading: false,
    searchParams: {
      pageSize: 10,
      pageIndex: 0,
      orderBy: "code",
      AppraisalStartDate: null,
      appraisalEndDate: null,
      code: null,
      templateIdList: null,
      isAscend: false,
    },
    pageMeta: {
      currentPage: 1,
      totalCount: 10,
      pageSize: 10,
    },
    options: {
      attendanceFile: [],
      siteKPI: [],
      degree360: [],
    },
    visible: false,
  }

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "isLoading":
        return { ...state, isLoading: payload }

      case "pageChange":
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            ...payload,
          },
        }
      case "fetchData":
        return {
          ...state,
          dataSource: payload.content.appraisalCycleGroup,
          pageMeta: {
            currentPage: payload.content.appraisalCycleGroup.currentPage,
            totalCount: payload.content.appraisalCycleGroup.totalCount,
            pageSize: payload.content.appraisalCycleGroup.pageSize,
          },
          isLoading: false,
        }
      case "searchParams":
        return {
          ...state,
          searchParams: {
            ...payload,
            pageIndex: 0,
            pageSize: 10,
            orderBy: "code",
            isAscend: false,
          },
        }
      case "options":
        return {
          ...state,
          options: payload,
        }
      case "close":
        return {
          ...state,
          visible: false,
        }
      case "modal":
        return {
          ...state,
          visible: true,
        }
      default:
        break
    }
  }
  const [
    { isLoading, dataSource, searchParams, pageMeta, visible, options },
    dispatch,
  ] = useReducer(reducer, initialState)

  const getData = useCallback(() => {
    const callApi = async params => {
      dispatch({ type: "isLoading", payload: true })
      const client = new AppraisalCycleClient()
      const res = await client
        .search(
          params.pageSize,
          params.pageIndex,
          params.orderBy,
          params.isAscend,
          params.appraisalStartDate,
          params.appraisalEndDate,
          params.code,
          params.templateIdList
        )
        .catch(err => {
          console.log(err)
          return {
            content: {
              appraisalCycleGroup: [],
              paginationMetadata: {},
            },
          }
        })

      dispatch({ type: "fetchData", payload: res })
    }
    callApi(searchParams)
  }, [searchParams])

  useEffect(() => {
    const getOptions = async () => {
      const degree360client = new Degree360SupervisorSetClient()
      const degree360Res = await degree360client
        .get(0, 0, "id", true)
        .catch(err => {
          console.log(err)
          return {
            content: {
              degree360SupervisorSetGroup: [],
            },
          }
        })

      const degree360 = degree360Res.content.degree360SupervisorSetGroup.filter(
        item => item.isEnabled
      )

      const siteKPIClient = new SiteKPISetClient()
      const siteRes = await siteKPIClient.get(0, 0).catch(err => {
        console.log(err)
        return {
          content: {
            siteKPISetGroup: [],
          },
        }
      })

      const attendanceClient = new AttendanceRecordFileClient()
      const attendanceRes = await attendanceClient
        .get(true, "id", true, 0, 0)
        .catch(err => {
          console.log(err)
          return {
            list: [],
          }
        })

      dispatch({
        type: "options",
        payload: {
          attendanceFile: attendanceRes.list,
          siteKPI: siteRes.content.siteKPISetGroup,
          degree360: degree360,
        },
      })
    }
    getOptions()
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = [
    {
      title: "Cycle Name",
      dataIndex: "name",
      sorter: true,
    },

    {
      title: "Cycle Code",
      dataIndex: "code",
      sorter: true,
    },

    {
      title: "Appraisal Period",
      dataIndex: "appraisalStartDate",
      sorter: true,
      render: (_, record) =>
        transformDate(record.appraisalStartDate) +
        " to " +
        transformDate(record.appraisalEndDate),
    },
    {
      title: "Attendance Record",
      dataIndex: "attendanceRecordFileName",
      render: text => text ?? "-",
    },
    {
      title: "Site KPI",
      dataIndex: "siteKPISetName",
      sorter: true,
      render: text => text ?? "-",
    },
    {
      title: "Degree 360 Supervisor",
      dataIndex: "degree360SupervisorSetName",
      sorter: true,
      render: text => text ?? "-",
    },
    {
      title: "Goal Achievement Period",
      dataIndex: "goalStartDate",
      sorter: true,
      render: (_, record) =>
        record.goalStartDate === null || record.goalEndDate === null
          ? " - "
          : transformDate(record.goalStartDate) +
            " to " +
            transformDate(record.goalEndDate),
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Button
            shape="round"
            icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              navigate("/configuration/cycle", { state: { id: record.id } })
            }}
          >
            View
          </Button>
        )
      },
    },
  ]
  return (
    <>
      <SearchForm
        setSearchParams={values =>
          dispatch({ type: "searchParams", payload: values })
        }
      />

      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            dispatch({ type: "modal" })
          }}
        >
          Add
        </Button>
      </Space>

      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          dispatch({ type: "pageChange", payload: values })
        }
        defaultOrder={{
          orderBy: "code",
          isAscend: false,
        }}
      />
      <EditModal
        visible={visible}
        options={options}
        onCancel={() => {
          dispatch({ type: "close" })
        }}
      />
    </>
  )
}

export default AppraisalCycle
