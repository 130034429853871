import React, { useReducer, useEffect, useCallback } from "react"
import { PanelTable, YesOrNoIcon, ViewButton } from "lib"
import { Button, Space } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { CoreCompetencyDefinitionSetClient } from "@api"
import { navigate, Link } from "gatsby"

const CoreCompetencySet = () => {
  const initialState = {
    isLoading: false,
    dataSource: [],
    searchParams: {
      name: null,
      code: null,
      fullMarksFrom: null,
      fullMarksTo: null,
      isEnabled: null,
      pageIndex: 0,
      pageSize: 10,
      orderBy: "id",
      isAscend: false,
    },
    pageMeta: {
      currentPage: 1,
      totalCount: 10,
      pageSize: 10,
    },
  }

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "isLoading":
        return { ...state, isLoading: payload }

      case "pageChange":
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            ...payload,
          },
        }
      case "fetchData":
        return {
          ...state,
          dataSource: payload.items,
          pageMeta: {
            currentPage: payload.pageIndex,
            totalCount: payload.totalCount,
            pageSize: payload.pageSize,
          },
          isLoading: false,
        }
      default:
        break
    }
  }
  const [{ isLoading, dataSource, searchParams, pageMeta }, dispatch] =
    useReducer(reducer, initialState)

  const getData = useCallback(() => {
    const callApi = async params => {
      dispatch({ type: "isLoading", payload: true })
      const client = new CoreCompetencyDefinitionSetClient()
      const result = await client
        .get(
          params.name,
          params.code,
          params.isEnabled,
          params.fullMarksFrom,
          params.fullMarksTo,
          params.pageIndex + 1,
          params.pageSize,
          params.orderBy,
          params.isAscend
        )
        .catch(err => {
          console.log(err)
          return {
            items: [],
          }
        })
      dispatch({ type: "fetchData", payload: result })
    }

    callApi(searchParams)
  }, [searchParams])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
    },

    {
      title: "Full Marks",
      dataIndex: "fullMarks",
      sorter: true,
    },
    {
      title: "Default Approval Path",
      dataIndex: "defaultApprovalPathName",
      sorter: true,
    },
    {
      title: "Enabled",
      dataIndex: "isEnabled",
      render: value => <YesOrNoIcon value={value} />,
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <ViewButton
              onClick={() => {
                navigate("/configuration/coreCompetencySet", {
                  state: { id: record.id },
                })
              }}
            />
          </Space>
        )
      },
    },
  ]
  return (
    <div>
      <Space size="small" className="table-Buttom">
        <Link to="/configuration/coreCompetencySet">
          <Button shape="round" type="primary" icon={<PlusOutlined />}>
            Add
          </Button>
        </Link>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={value => {
          dispatch({ type: "pageChange", payload: value })
        }}
        defaultOrder={{
          orderBy: "id",
          isAscend: false,
        }}
      />
    </div>
  )
}

export default CoreCompetencySet
