import React, { useState, useEffect } from "react"
import { Button, Space, Form, Row, Col, Select, Input } from "antd"
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import "../admin_table.less"
import {
  ApprovalRoleMappingClient,
  OtherClient,
  ApprovalRoleClient,
} from "@api"
import { showSuccess, showError, DownloadData, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, Loading, EditButton, DeleteButton } from "lib"
import UploadModal from "@components/UploadModal"

const EditModal = ({
  isCreate,
  isNew,
  visible,
  onCancel,
  options,
  record,
  refresh,
}) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!isNew && record) {
      form.setFieldsValue({
        ...record,
        approvalSiteId: record.appraiseeSiteId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new ApprovalRoleMappingClient()
    try {
      if (isCreate) {
        await client.create(values)
        showSuccess("Create Item Success")
      } else {
        await client.edit(record.id, { id: record.id, ...values })

        showSuccess("Edit Item Success")
      }
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(
        isCreate ? "Create Approval Mapping" : "Edit Approval Mapping",
        err
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Approval Mapping"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="approvalSiteId"
          label="Approval Site / Department"
          rules={[
            { required: true, message: "Please select a site or department" },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a Site / Department"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.siteOrDepartmentList.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="approvalRoleId"
          label="Role"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select
            showSearch
            placeholder="Select a Role"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.roleList.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="approverId"
          label="Staff Number"
          rules={[{ required: true, message: "Please input a staff number" }]}
        >
          <Input placeholder="Input a staff number" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const SearchForm = ({ oldSearchParams, setSearchParams, options }) => {
  const { Option } = Select
  const [form] = Form.useForm()

  const onFinish = values => {
    console.log(values)
    let newValues = {
      ...oldSearchParams,
      pageIndex: 0,
      staffNo: values.staffNo,
      staffName: values.staffName,
      siteIdList: values.siteIdList,
      approvalRoleIdList: values.approvalRoleIdList,
    }
    setSearchParams(newValues)
  }
  return (
    <Row gutter={24} className="admin_table">
      <Form layout="inline" onFinish={onFinish} form={form}>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a Staff Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a Staff Name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Site / Department" name="siteIdList">
            <Select
              placeholder="Select a Site / Department"
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.siteOrDepartmentList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Role" name="approvalRoleIdList">
            <Select
              mode="multiple"
              placeholder="Select a Role"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.roleList.map(item => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

const ApprovalMap = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [pageMeta, setPageMeta] = useState(null)
  const [options, setOptions] = useState(undefined)
  const [roleOptions, setRoleOptions] = useState([])
  const [uploadVisible, setUploadVisible] = useState(false)
  const [modalVars, setModalVars] = useState({
    isNew: true,
    isCreate: true,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "approverId",
    isAscend: true,
    staffNo: null,
    staffName: null,
    siteIdList: null,
    approvalRoleIdList: null,
  })

  const getOptions = async () => {
    let client
    let res
    try {
      client = new OtherClient()
      res = await client.get()
      setOptions(res.content)
      client = new ApprovalRoleClient()

      res = await client.get(0, 0, "name", true)
      setRoleOptions(res.content.approvalRoleGroup)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      showError(errmsg.errors)
    }
  }

  const getData = async params => {
    setIsLoading(true)
    let client = new ApprovalRoleMappingClient()
    console.log(params)
    try {
      let result = await client.search(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName,
        params.siteIdList,
        params.approvalRoleIdList
      )
      if (result.isSucceeded) {
        setDataSource(result.content.approvalRoleMappingGroup)
        setPageMeta(result.content.paginationMetadata)
        setIsLoaded(true)
      } else {
        throw Error(result.errors)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new ApprovalRoleMappingClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Appraisee",
      children: [
        {
          title: "Site / Department",
          dataIndex: "appraiseeSite",
          sorter: true,
        },
      ],
    },
    { title: "Role", dataIndex: "approvalRole", sorter: true },
    {
      title: "",
      children: [
        { title: "Staff Number", dataIndex: "approverId", sorter: true },
        { title: "Staff Name", dataIndex: "approver", sorter: true },
      ],
    },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  isCreate: false,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            />

            <DeleteButton
              onClick={() => {
                deleteData(record.id)
              }}
            />
          </Space>
        )
      },
    },
  ]
  return options && isLoaded ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
        options={{ ...options, roleList: roleOptions }}
      />
      <UploadModal
        visible={uploadVisible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setUploadVisible(false)
        }}
        title={"Approval Role Mapping"}
        apiClient={ApprovalRoleMappingClient}
      />
      <EditModal
        isNew={modalVars.isNew}
        isCreate={modalVars.isCreate}
        visible={visible}
        record={modalVars.record}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => getData(searchParams)}
        options={{ ...options, roleList: roleOptions }}
      />

      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isCreate: true,
              isNew: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>

        <Button
          shape="round"
          icon={<UploadOutlined />}
          onClick={() => {
            setUploadVisible(true)
          }}
        >
          Upload
        </Button>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => DownloadData(ApprovalRoleMappingClient)}
        >
          Download
        </Button>
      </Space>

      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default ApprovalMap
