import React, { useState, useEffect } from "react"
import { Button, Form, Input, Table, Modal, Checkbox } from "antd"
import { EyeOutlined } from "@ant-design/icons"
import "../admin_table.less"
import { NotificationTemplatesClient, NotificationTemplateType } from "@api"
import { showSuccess, errorMessage } from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, Loading, EditButton } from "lib"
import { FormattedMessage } from "react-intl"

const TokenTable = ({ emailType, setTemplateType }) => {
  const appraisalUpdatedTokenList = [
    {
      token: "{Recipent}",
      description: "Name of Recipent",
    },
    {
      token: "{AppraisalNo}",
      description: "Appraisal Number",
    },
    {
      token: "{AppraisalLink}",
      description: "Appraisal Form Link",
    },
    {
      token: "{Appraisee}",
      description: "Name of Appraisee",
    },
    {
      token: "{Site}",
      description: "Name of Site / Department",
    },
    {
      token: "{Position}",
      description: "Name of Position",
    },

    {
      token: "{Status}",
      description: "Task current status",
    },

    {
      token: "{History}",
      description: "The last action of appraisal",
    },
  ]

  const reminderTokenList = [
    { token: "{Recipent}", description: "Name of Recipent" },
    { token: "{MyTaskLink}", description: "HyperLink of MyTask page" },
    { token: "{Records}", description: "List of pending tasks" },
  ]

  const degree360ReminderTokenList = [
    { token: "{Recipent}", description: "Name of Recipent" },
    { token: "{MyTaskLink}", description: "HyperLink of MyTask page" },
  ]
  const uploadAppraisalTaskReminderTokenList = [
    { token: "{Recipent}", description: "Name of Recipent" },
    { token: "{FrontlineLink}", description: "HyperLink of Frontline page" },
  ]

  const columns = [
    {
      title: "Token",
      dataIndex: "token",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
  ]

  const getTokenList = templateType => {
    switch (templateType) {
      case NotificationTemplateType.AppraisalUpdated:
        setTemplateType("AppraisalUpdated")
        return appraisalUpdatedTokenList
      case NotificationTemplateType.AppraisalReminder:
        setTemplateType("AppraisalReminder")
        return reminderTokenList
      case NotificationTemplateType.ApproverReminder:
        setTemplateType("ApproverReminder")
        return reminderTokenList
      case NotificationTemplateType.Degree360Reminder:
        setTemplateType("Degree360Reminder")
        return degree360ReminderTokenList
      case NotificationTemplateType.UploadAppraisalTaskCompletedReminder:
        setTemplateType("UploadAppraisalTaskCompletedReminder")
        return uploadAppraisalTaskReminderTokenList
      default:
        return []
    }
  }

  return (
    <div>
      <h4>Token List</h4>
      <Table
        dataSource={getTokenList(emailType)}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  )
}

const EditModal = ({ isNew, visible, onCancel, record, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [templateType, setTemplateType] = useState(null)
  const { TextArea } = Input

  useEffect(() => {
    if (!isNew && visible) {
      form.setFieldsValue({
        ...record,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const submitForm = async values => {
    setIsLoading(true)
    let client = new NotificationTemplatesClient()
    try {
      await client.edit(record.id, {
        id: record.id,

        ...values,
      })
      console.log(values)
      showSuccess("Edit Template Success")
      refresh()
      onCancel()
    } catch (err) {
      errorMessage("Edit Template", err)
    } finally {
      setIsLoading(false)
    }
  }

  const previewContent = (values, isSubmit) => {
    const { confirm, info } = Modal
    if (isSubmit) {
      confirm({
        width: "65%",
        centered: true,
        title: "Confirm email content",
        content: <div dangerouslySetInnerHTML={{ __html: values.content }} />,
        cancelText: "Cancel",
        onOk: () => {
          submitForm(values)
        },
        okText: "Confirm",
      })
    } else {
      info({
        width: "65%",
        centered: true,
        okText: "Ok",
        title: "Preview email content",
        content: <div dangerouslySetInnerHTML={{ __html: values }} />,
      })
    }
  }

  const onFinish = values => {
    previewContent(values, true)
  }

  return (
    <FormModal
      isCreate={false}
      form={form}
      onCancel={onCancel}
      title={`Email Template - ${NotificationTemplateType[record?.id]}`}
      isLoading={isLoading}
      visible={visible}
      width="65%"
    >
      <Form preserve={false} form={form} onFinish={onFinish} layout="vertical">
        <Form.Item>
          <TokenTable
            setTemplateType={e => setTemplateType(e)}
            emailType={record?.id}
          />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: "Please input a subject" }]}
        >
          <Input placeholder="Input a subject" />
        </Form.Item>

        <Form.Item
          name="content"
          label={
            <div>
              {"Content - "}
              <Button
                type="link"
                onClick={() => {
                  let content = form.getFieldValue("content")
                  previewContent(content, false)
                }}
              >
                <EyeOutlined /> Preview
              </Button>
            </div>
          }
          rules={[{ required: true, message: "Please input content" }]}
        >
          <TextArea autoSize placeholder="Input a content" />
        </Form.Item>

        {(templateType === "AppraisalReminder" ||
          templateType === "ApproverReminder") && (
          <Form.Item label="Notification Frequency" name="dayofWeek">
            <Checkbox.Group>
              <Checkbox value={0}>
                <FormattedMessage id="sunday" />
              </Checkbox>

              <Checkbox value={1}>
                <FormattedMessage id="monday" />
              </Checkbox>

              <Checkbox value={2}>
                <FormattedMessage id="tuesday" />
              </Checkbox>

              <Checkbox value={3}>
                <FormattedMessage id="wednesday" />
              </Checkbox>

              <Checkbox value={4}>
                <FormattedMessage id="thursday" />
              </Checkbox>

              <Checkbox value={5}>
                <FormattedMessage id="friday" />
              </Checkbox>

              <Checkbox value={6}>
                <FormattedMessage id="saturday" />
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        )}
      </Form>
    </FormModal>
  )
}

const EmailTemplate = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [modalVars, setModalVars] = useState({
    record: null,
    isNew: false,
  })
  const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    orderBy: "id",
    isAscend: true,
  })
  const [pageMeta, setPageMeta] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    let client = new NotificationTemplatesClient()
    try {
      let result = await client.get(
        params.orderBy,
        params.isAscend,
        params.pageSize,
        params.pageIndex
      )
      setPageMeta(result.paginationMetadata)
      setDataSource(result.list)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Template Name",
      dataIndex: "id",
      sorter: true,
      render: id => NotificationTemplateType[id],
    },
    { title: "Subject", dataIndex: "subject", sorter: true },
    {
      title: "Content",
      dataIndex: "content",
      render: text => <div dangerouslySetInnerHTML={{ __html: text }} />,
      sorter: true,
    },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <EditButton
            onClick={() => {
              setModalVars({
                isNew: false,
                record: record,
              })
              setVisible(true)
            }}
          />
        )
      },
    },
  ]
  return isLoaded ? (
    <>
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      />
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default EmailTemplate
