import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input } from "antd"
import { PlusOutlined, SearchOutlined } from "@ant-design/icons"
import "../admin_table.less"
import { showError, showSuccess, errorMessage } from "@action"
import { Link, navigate } from "gatsby"
import { AttendanceRecordFileClient } from "@api"
import { PanelTable, Loading, DeleteButton, EnableButton } from "lib"
import FormModal from "@components/FormModal"

const EditModal = ({ visible, onCancel, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const onFinish = async values => {
    setIsLoading(true)
    let client = new AttendanceRecordFileClient()
    try {
      let result = await client.create(values)

      showSuccess("Add Request Success")
      refresh()
      onCancel()
      navigate("/configuration/attendance/", {
        state: { id: result },
      })
    } catch (err) {
      errorMessage("Add Attendance Record File", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={true}
      form={form}
      onCancel={onCancel}
      title="Attendance Record Set"
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="name"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a title" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const AttendanceRecord = () => {
  const [visible, setVisible] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState(null)
  const [searchParams, setSearchParams] = useState({
    isEnabled: null,
    orderBy: "name",
    isAscend: true,
    pageIndex: 0,
    pageSize: 10,
  })
  const getData = async params => {
    setIsLoading(true)
    let client = new AttendanceRecordFileClient()
    try {
      let res = await client.get(
        params.isEnabled,
        params.orderBy,
        params.isAscend,
        params.pageSize,
        params.pageIndex
      )
      setDataSource(res.list)
      setPageMeta(res.paginationMetadata)
      setIsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new AttendanceRecordFileClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const changeAble = async record => {
    let client = new AttendanceRecordFileClient()
    try {
      await client.enableOrDisable(record.id, {
        id: record.id,
        isEnabled: !record.isEnabled,
      })
      showSuccess(
        record.isEnabled ? "Disable Item Success" : "Enable Item Success"
      )
      getData(searchParams)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    { title: "Title", dataIndex: "name", sorter: true },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Link to="/configuration/attendance/" state={{ id: record.id }}>
              <Button shape="round" icon={<SearchOutlined />} size="small">
                View
              </Button>
            </Link>
            {record.hasDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]

  return isLoaded ? (
    <>
      <EditModal
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => {
          getData(searchParams)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>

      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default AttendanceRecord
