import React, { useState } from "react"
import { Form, Select, DatePicker, Input } from "antd"
import { AppraisalCycleClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { showSelectProps } from "@object"
import FormModal from "@components/FormModal"
import { navigate } from "gatsby"
import locale from "antd/es/date-picker/locale/en_US"

const EditModal = ({ visible, onCancel, options }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { Option } = Select
  const { RangePicker } = DatePicker

  const onFinish = async values => {
    const submitValues = {
      ...values,
      cutOffDate: values.cutOffDate.utcOffset(0, true).toJSON(),
      appraisalStartDate: values.appraisalPeriod[0].utcOffset(0, true).toJSON(),
      appraisalEndDate: values.appraisalPeriod[1].utcOffset(0, true),
      goalStartDate: values.goalPeriod
        ? values.goalPeriod[0].utcOffset(0, true).toJSON()
        : null,
      goalEndDate: values.goalPeriod
        ? values.goalPeriod[1].utcOffset(0, true).toJSON()
        : null,
    }
    try {
      const client = new AppraisalCycleClient()
      const result = await client.create(submitValues)
      showSuccess("Add Appraisal Cycle Success")
      navigate("/configuration/cycle/", {
        state: { id: result },
      })
      onCancel()
    } catch (err) {
      errorMessage("Add Appraisal Cycle", err)
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={true}
      form={form}
      onCancel={onCancel}
      title={"Appraisal Cycle"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" preserve={false} onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          label="Cycle Name"
          rules={[{ required: true, message: "Please input a name" }]}
        >
          <Input placeholder="Input a Cycle Name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Cycle Code"
          rules={[{ required: true, message: "Please input a code" }]}
        >
          <Input placeholder="Input a Cycle Code" />
        </Form.Item>
        <Form.Item
          name="appraisalPeriod"
          label="Appraisal Period"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <RangePicker format={process.env.DATE_FORMAT} locale={locale} />
        </Form.Item>
        <Form.Item
          name="cutOffDate"
          label="Cut off Date"
          rules={[{ required: true, message: "Please select a date" }]}
        >
          <DatePicker
            format={process.env.DATE_FORMAT}
            placeholder="Select a Date"
            locale={locale}
          />
        </Form.Item>

        <Form.Item name="attendanceRecordFileId" label="Attendance Record">
          <Select
            placeholder="Select a Attendance Record"
            {...showSelectProps}
            allowClear
          >
            {options.attendanceFile.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="siteKPISetId" label="Site KPI">
          <Select
            placeholder="Select a Site KPI"
            {...showSelectProps}
            allowClear
          >
            {options.siteKPI.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="goalPeriod" label="Goal Achievement Period">
          <RangePicker format={process.env.DATE_FORMAT} locale={locale} />
        </Form.Item>

        <Form.Item
          name="degree360SupervisorSetId"
          label="Degree 360 Supervisor"
        >
          <Select
            placeholder="Select a Degree 360 Supervisor"
            {...showSelectProps}
            allowClear
          >
            {options.degree360.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
