import React, { useState, useEffect } from "react"
import { Button, Space, Spin } from "antd"
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons"
import "../admin_table.less"
import { SupervisorClient, OtherClient } from "@api"
import { checkPermission } from "@services/auth"
import UploadModal from "@components/UploadModal"
import { showSuccess, DownloadData } from "@action"
import { PanelTable, DeleteButton, EditButton } from "lib"
import EditModal from "./EditModal"
import GenerModal from "./GenerModal"
import SearchForm from "./SearchForm"
import { FormattedMessage, useIntl } from "react-intl"

const SupervisorTable = () => {
  const [editUser, setEditUser] = useState(false)
  const [genVisible, setGenVisible] = useState(false)
  const [uploadVisible, setUploadVisible] = useState(false)
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [pageMeta, setPageMeta] = useState(null)
  const [modalVars, setModalVars] = useState({
    isCreate: true,
    isNew: true,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    orderBy: "appraiseeName",
    isAscend: true,
    staffNo: null,
    staffName: null,
    supStaffNo: null,
    supStaffName: null,
    companyIdList: null,
    siteIdList: null,
    positionIdList: null,
    pageSize: 10,
    pageIndex: 0,
  })
  const [isInit, setIsInit] = useState(true)

  const { formatMessage } = useIntl()

  const supervisorLabel = formatMessage({ id: "supervisorDivider" })

  const getData = async params => {
    setIsLoading(true)
    const client = new SupervisorClient()
    try {
      const res = await client.search(
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName,
        params.supStaffNo,
        params.supStaffName,
        params.companyIdList,
        params.siteIdList,
        params.positionIdList,
        params.pageSize,
        params.pageIndex
      )
      setDataSource(res.list)
      setPageMeta(res.paginationMetadata)
      setIsInit(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const checkIsUser = async () => {
    let res = await checkPermission("User")
    setEditUser(res)
  }

  const getOptions = async () => {
    let client = new OtherClient()
    let res
    try {
      res = await client.get()
      setOptions(res.content)
    } catch (err) {
      console.log(err)
    }
  }

  const deleteData = async id => {
    setIsLoading(true)
    let client = new SupervisorClient()
    try {
      await client.delete(id)
      getData(searchParams)
      showSuccess("Delete item success !")
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkIsUser()
    getOptions()
    getData(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isInit) {
      getData(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    {
      title: <FormattedMessage id="staffNo" />,
      dataIndex: "appraiseeStaffNo",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisee" />,
      dataIndex: "appraiseeName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="company" />,
      dataIndex: "appraiseeCompanyName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "appraiseeSiteOrDepartmentName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "appraiseePositionName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="supervisorStaffNo" />,
      dataIndex: "supervisorStaffNo",
      sorter: true,
    },
    {
      title: <FormattedMessage id="supervisorStaffName" />,
      dataIndex: "supervisorName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="supervisorLevel" />,
      dataIndex: "level",
      sorter: true,
    },
    {
      title: <FormattedMessage id="action" />,
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              isTranslate
              onClick={() => {
                setModalVars({
                  isCreate: false,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            />

            <DeleteButton
              isTranslate
              onClick={() => {
                deleteData(record.id)
              }}
            />
          </Space>
        )
      },
    },
  ]

  return !isInit ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
        options={options}
      />
      <UploadModal
        isTranslate
        visible={uploadVisible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setUploadVisible(false)
        }}
        title={supervisorLabel}
        apiClient={SupervisorClient}
      />
      <GenerModal
        visible={genVisible}
        onCancel={() => {
          setGenVisible(false)
        }}
      />
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        options={options}
        record={modalVars.record}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => getData(searchParams)}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isCreate: true,
              isNew: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          <FormattedMessage id="add" />
        </Button>
        {editUser && (
          <Button
            shape="round"
            icon={<UploadOutlined />}
            onClick={() => {
              setUploadVisible(true)
            }}
          >
            <FormattedMessage id="upload" values={{ name: "" }} />
          </Button>
        )}
        {editUser && (
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => {
              DownloadData(SupervisorClient)
            }}
          >
            <FormattedMessage id="download" />
          </Button>
        )}
        {editUser && (
          <Button
            shape="round"
            icon={<UsergroupAddOutlined />}
            onClick={() => {
              setGenVisible(true)
            }}
          >
            <FormattedMessage id="generateRandomSubordinate" />
          </Button>
        )}
      </Space>
      <PanelTable
        isTranslate
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default SupervisorTable
