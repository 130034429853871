import React, { useState, useEffect } from "react"
import { Button, Space, Form, Input } from "antd"
import { SearchOutlined, PlusOutlined } from "@ant-design/icons"
import "../admin_table.less"
import { Link, navigate } from "gatsby"
import { AppraisalTargetSetClient } from "@api"
import { showSuccess, showError } from "@action"
import FormModal from "@components/FormModal"
import { PanelTable, EnableButton, DeleteButton, Loading } from "lib"

const EditModal = ({ visible, onCancel, refresh }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async values => {
    setIsLoading(true)
    let client = new AppraisalTargetSetClient()

    try {
      let result = await client.create(values)

      if (result.isSucceeded) {
        showSuccess("Add Request Success")
        refresh()
        onCancel()
        navigate("/configuration/target/", {
          state: { id: result.content },
        })
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)

      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={true}
      form={form}
      onCancel={onCancel}
      title="Target Set"
      isLoading={isLoading}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} preserve={false}>
        <Form.Item
          name="name"
          label="Title"
          rules={[{ required: true, message: "Please input a title" }]}
        >
          <Input placeholder="Input a title" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const AppraisalTarget = () => {
  const [isLoad, setIsLoad] = useState(false)
  const [visible, setVisible] = useState(false)
  const [searchParams, setSearchParams] = useState({
    pageSize: 10,
    pageIndex: 0,
    orderBy: "name",
    isAscend: true,
  })
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getData = async params => {
    setIsLoading(true)
    let client = new AppraisalTargetSetClient()
    try {
      let result = await client.get(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend
      )
      setPageMeta(result.content.paginationMetadata)
      setDataSource(result.content.appraisalTargetSetGroup)
      setIsLoad(true)
    } catch (err) {
      console.log()
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new AppraisalTargetSetClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const changeAble = async data => {
    let client = new AppraisalTargetSetClient()
    client
      .enableOrDisable({ id: data.id, isEnabled: !data.isEnabled })
      .then(() => {
        showSuccess(
          data.isEnabled ? "Disable Item Success" : "Enable Item Success"
        )
        getData(searchParams)
      })
      .catch(err => {
        let errmsg = JSON.parse(err.response)
        console.log(errmsg)
        showError(errmsg.errors)
      })
  }

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    { title: "Title", dataIndex: "name", sorter: true },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <Link to="/configuration/target/" state={{ id: record.id }}>
              <Button shape="round" icon={<SearchOutlined />} size="small">
                View
              </Button>
            </Link>

            {record.isCanDelete ? (
              <DeleteButton
                onClick={() => {
                  deleteData(record.id)
                }}
              />
            ) : (
              <EnableButton
                isEnabled={record.isEnabled}
                onClick={() => {
                  changeAble(record)
                }}
              />
            )}
          </Space>
        )
      },
    },
  ]
  return isLoad ? (
    <>
      <EditModal
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => {
          getData(searchParams)
        }}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setVisible(true)
          }}
        >
          Add
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default AppraisalTarget
