import React, { useState } from "react"
import { Form, Input } from "antd"
import { DefaultApprovalPathsClient } from "@api"
import { showSuccess, errorMessage } from "@action"
import { FormModal } from "lib"
import { navigate } from "gatsby"

const EditModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async values => {
    setIsLoading(true)

    try {
      const client = new DefaultApprovalPathsClient()
      const result = await client.create(values)
      showSuccess("Create Item Success")

      onCancel()
      navigate(`/configuration/approvalPath/`, { state: { id: result } })
    } catch (err) {
      errorMessage("Create Approval Path", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={true}
      form={form}
      onCancel={onCancel}
      title={"Approval Path"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input a name",
            },
          ]}
        >
          <Input placeholder="Please input a name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input a code",
            },
          ]}
        >
          <Input placeholder="Please input a Code" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

export default EditModal
