import React, { useState, useEffect } from "react"
import { Button, Space, Form, Row, Col, Select, Input } from "antd"
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import "../admin_table.less"
import FormModal from "@components/FormModal"
import { PanelTable, Loading, DeleteButton, EditButton } from "lib"
import { OtherClient, SiteAdminClient } from "@api"
import { showSuccess, showError, DownloadData, errorMessage } from "@action"
import UploadModal from "@components/UploadModal"

const EditModal = ({ isNew, visible, onCancel, options, record, refresh }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNew && record) {
      form.setFieldsValue({
        ...record,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    setIsLoading(true)
    let client = new SiteAdminClient()
    let result
    try {
      if (isNew) {
        result = await client.create(values)
      } else {
        result = await client.edit(record.id, { id: record.id, ...values })
      }
      if (result.isSucceeded) {
        showSuccess(isNew ? "Add Request Success" : "Change Request Success")
        refresh()
        onCancel()
      }
    } catch (err) {
      errorMessage(isNew ? "Add Site Admin" : "Change Site Admin", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isNew}
      form={form}
      onCancel={onCancel}
      title={"Site Admin"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="siteOrDepartmentId"
          label="Site / Department"
          rules={[
            { required: true, message: "Please select a site or department" },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a Site / Department"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.map(item => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="staffNo"
          label="Staff Number"
          rules={[{ required: true, message: "Please input a staff number" }]}
        >
          <Input placeholder="Input a Number" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}
const SearchForm = ({ oldSearchParams, setSearchParams, options }) => {
  const [form] = Form.useForm()
  const { Option } = Select

  const onFinish = values => {
    let newValues = {
      ...oldSearchParams,
      pageIndex: 0,
      staffNo: values.staffNo,
      staffName: values.staffName,
      siteIdList: values.siteIdList,
    }

    setSearchParams(newValues)
  }

  return (
    <Row gutter={24} className="admin_table">
      <Form layout="inline" form={form} onFinish={onFinish}>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a Staff Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a Staff Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Site / Department" name="siteIdList">
            <Select
              mode="multiple"
              placeholder="Select a Site / Department"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}
const SiteAdmin = () => {
  const [visible, setVisible] = useState(false)
  const [uploadVisible, setUploadVisible] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState(undefined)
  const [pageMeta, setPageMeta] = useState(null)
  const [modalVars, setModalVars] = useState({
    record: null,
    isCreate: false,
    isNew: false,
  })

  const [searchParams, setSearchParams] = useState({
    pageSize: 10,
    pageIndex: 0,
    orderBy: "staffNo",
    isAscend: true,
    staffNo: null,
    staffName: null,
    siteIdList: null,
  })

  const getOptions = async () => {
    let client = new OtherClient()
    try {
      let result = await client.get()
      setOptions(result.content.siteOrDepartmentList)
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  const getData = async params => {
    setIsLoading(true)
    let client = new SiteAdminClient()
    try {
      let result = await client.search(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend,
        params.staffNo,
        params.staffName,
        params.siteIdList
      )
      if (result.isSucceeded) {
        setDataSource(result.content.siteAdminGroup)
        setPageMeta(result.content.paginationMetadata)
        setIsLoaded(true)
      } else {
        throw Error(result.errors)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteData = async id => {
    let client = new SiteAdminClient()
    try {
      await client.delete(id)
      showSuccess("Delete Item Success")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      let errmsg = JSON.parse(err.response)
      console.log(errmsg)
      showError(errmsg.errors)
    }
  }

  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    getData(searchParams)
  }, [searchParams])

  const columns = [
    {
      title: "Site / Department",
      dataIndex: "siteOrDepartmentName",
      sorter: true,
    },

    { title: "Staff Number", dataIndex: "staffNo", sorter: true },

    { title: "Staff Name", dataIndex: "staffName", sorter: true },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  isNew: false,
                  isCreate: false,
                  record: record,
                })
                setVisible(true)
              }}
            />
            <DeleteButton onClick={() => deleteData(record.id)} />
          </Space>
        )
      },
    },
  ]
  return isLoaded && options ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
        options={options}
      />
      <EditModal
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        record={modalVars.record}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setVisible(false)
        }}
        options={options}
      />
      <UploadModal
        visible={uploadVisible}
        onCancel={() => {
          setUploadVisible(false)
        }}
        refresh={() => {
          getData(searchParams)
        }}
        title={"Site Admin"}
        apiClient={SiteAdminClient}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isNew: true,
              isCreate: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>

        <Button
          shape="round"
          icon={<UploadOutlined />}
          onClick={() => {
            setUploadVisible(true)
          }}
        >
          Upload
        </Button>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => {
            DownloadData(SiteAdminClient)
          }}
        >
          Download
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Loading />
  )
}

export default SiteAdmin
